import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { palette } from '@mirinae/react-ui';

import { Button, Popup } from '../gnbStyles';

const linkStyle = `
    display: block;
    width: 100%;
    padding: 10px 20px;
    line-height: 24px;
    color: ${palette.gray[1]};
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    :hover {
        background-color: ${palette.gray[8]};
    }
`;

const Layer = styled(Popup)`
    width: 195px;
    overflow: hidden;
`;
const LayerHead = styled.div`
    padding: 15px 20px;
`;
const LayerBody = styled.div`
    border-top: 1px solid ${palette.gray[7]};
    a {
        ${linkStyle};
    }
`;
const UserName = styled.div`
    line-height: 24px;
    color: ${palette.primary.black};
    font-size: 14px;
    font-weight: 600;
    word-break: break-word;
`;
const UserEmail = styled.div`
    min-height: 21px;
    color: ${palette.gray[4]};
    font-size: 11px;
    font-weight: 400;
    word-break: break-word;
`;
const MenuButton = styled(Button)`
    ${linkStyle};
`;

const AuthLayer = ({
    localization = null,
    name = 'Team Mirinae',
    email = 'team@mirinae.io',
    links = { profile: '/profile', help: '/help' },
    onLogout = () => {},
}) => {

    return (
        <Layer>
            <LayerHead>
                <UserName>{name}</UserName>
                <UserEmail>{email}</UserEmail>
            </LayerHead>
            <LayerBody>
                {links.profile.match(/http/) ? (
                    <a href={links.profile}>{localization.account}</a>
                ) : (
                    <Link reloadDocument to={links.profile}>{localization.account}</Link>
                )}
                {links.helpcenter.match(/http/) ? (
                    <a href={links.helpcenter}>{localization.help}</a>
                ) : (
                    <Link reloadDocument to={links.helpcenter}>{localization.helpcenter}</Link>
                )}
                <MenuButton type="button" onClick={onLogout}>{localization['sign-out']}</MenuButton>
            </LayerBody>
        </Layer>
    );
};

export default AuthLayer;