import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import CustomScroll from 'react-custom-scroll';

import { zIndex, IconGlobal, palette, fontDefault } from '@mirinae/react-ui';

import IconCheckActive from '../assets/IconCheckActive';

const HeaderLangSelectWrapper = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    height: 18px;
    font-family: ${fontDefault};
`;

const ButtonWrapper = styled.button`
    height: 18px;
    color: ${palette.gray[3]};
    background-color: white;
    border: 0;
    font-size: 14px;
    font-weight: 600;
    svg {
        vertical-align: middle;
        margin: -3px 10px 0 0;
        path {
            stroke: ${palette.main.darken[1]}; 
        }
    }
    &:after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        margin: -2px 0 0 10px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4px 3px 0 3px;
        border-color: ${palette.main.darken[1]} transparent transparent transparent;
    }
    ${props => props.active && (`
        svg path {
            stroke: ${palette.main.darken[1]}; 
        }
        &:after {
            border-top-color: ${palette.main.darken[1]};
        }
    `)}
`;
const LangSelectLayer = styled.div`
    position: absolute;
    top: 29px;
    ${props => (props.theme === 'footer' ? `
        right: 0;
        z-index: ${zIndex.header - 1};
        @media only screen and (max-width: 600px) {
            left: 0;
            right: initial;
        }
    ` : props.theme === 'setting' ? `
        right: 0;
        z-index: ${zIndex.header + 1};
    ` : `
        left: 0;
        z-index: ${zIndex.header + 1};
    `)}
    min-width: 170px;
    padding: 15px 0;
    background-color: ${palette.primary.white};
    box-shadow: 0 0 15px rgba(196, 196, 196, 0.48);
    border-radius: 10px;
    .rcs-inner-container > div {
        padding: 0 10px;
    }
    .rcs-custom-scroll .rcs-custom-scrollbar {
        opacity: 1;
    }
`;
const LangItem = styled.div`
    display: flex;
    align-items: center;
    height: 34px;
    margin-bottom: 10px;
    padding: 0 10px;
    border-radius: 5px;
    color: ${palette.gray[3]};
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    &:last-child:after {
        content: '';
        display: block;
        height: 10px;
    }
    span {
        width: 100%;
    }
    ${props => (props.theme === 'active' && `
        background-color: rgba(135, 203, 200, 0.1);
        color: ${palette.main.darken[1]};
        font-weight: 600;
    `)}
    :hover {
        background-color: rgba(135, 203, 200, 0.1);
    }
`;
const LangSelector = ({ langsList, selected, onClickPrefs, theme }) => {
    if (!langsList || !selected) return null;
    const selectedLangEntry = Object.entries(langsList).find(o => o[0] === selected);
    const selectedLang = selectedLangEntry ? selectedLangEntry[1] : 'English';

    const [display, setDisplay] = useState(false);
    const $button = useRef();

    const handleClose = (e) => {
        if (e.target === $button.current) return;
        setDisplay(false);
    };

    useEffect(() => {
        document.body.addEventListener('click', handleClose, false);
        return () => {
            document.body.removeEventListener('click', handleClose);
        };
    }, []);
    return (
        <HeaderLangSelectWrapper>
            <ButtonWrapper type="button" active={display} ref={$button} onClick={() => { setDisplay(!display); }}>
                <IconGlobal color={palette.gray[3]} />
                {selectedLang}
            </ButtonWrapper>
            {display && (
                <LangSelectLayer theme={theme}>
                    <CustomScroll heightRelativeToParent="210px">
                        {Object.entries(langsList).map((d, i) => (
                            <LangItem key={i} theme={d[0] === selected ? 'active' : null} onClick={() => onClickPrefs({ language: d[0] })}>
                                <span>{d[1]}</span>
                                {d[0] === selected && <IconCheckActive />}
                            </LangItem>
                        ))}
                    </CustomScroll>
                </LangSelectLayer>
            )}
        </HeaderLangSelectWrapper>
    );
};

export default LangSelector;