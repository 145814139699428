import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { palette, breakPoint, zIndex } from '@mirinae/react-ui';
// import { buildExternalURL } from '@mirinae/js-utils';

import IconHelp from '../assets/IconHelp';
import { Button } from '../gnbStyles';

const Wrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    position: ${props => (props.theme === 'fixed' ? 'fixed' : 'absolute')};
    top: ${props => (props.theme === 'fixed' ? 'initial' : '-70px')};
    bottom: ${props => (props.theme === 'fixed' ? '0' : 'initial')};
    right: 0;
    z-index: ${zIndex.header - 1};
    padding: 20px;

    ${props => (!props.isApp && props.theme === 'fixed' && `
        @media only screen and (max-width: ${breakPoint.common.lg}) {
            transform: translateY(-105px);
        }
    `)}
`; 

const QuestionButton = styled(Button)`
    width: 30px;
    height: 30px;

    :hover svg path:not(:last-child) {
        fill: ${palette.main.darken[2]};
    }
`;

const BubbleLayer = styled.div`
    position: absolute;
    bottom: 60px;
    right: 20px;
    padding: 5px 0;
    background-color: ${palette.primary.white};
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(196, 196, 196, 0.48);
    a, button {
        display: block;
        width: 100%;
        min-width: 160px;
        height: 44px;
        line-height: 44px;
        padding: 0 15px;
        border: 0;
        background: none;
        white-space: nowrap;
        color:  ${palette.gray[3]};;
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        text-transform: capitalize;
        cursor: pointer;
        :hover {
            color: ${palette.primary.black};
            background-color: ${palette.gray[7]};
        }
    }
`;

let hover = false;
const HelpButton = ({ isApp, versionParam, localization, footerLinks, onClickManual, onClickFeedback }) => {
    const [pos, setPos] = useState('');
    const [displayLayer, setDisplayLayer] = useState(false);

    const handlePosition = (e) => {
        if (document.querySelector('footer') && (window.innerHeight > document.querySelector('footer').getBoundingClientRect().y)) {
            setPos('');
        } else {
            setPos('fixed');
        }
    };
    const handleClose = () => {
        if (hover) return;
        setDisplayLayer(false);
    };

    useEffect(() => {
        handlePosition();
        window.addEventListener('scroll', handlePosition, false);
        document.body.addEventListener('click', handleClose, false);
        return () => {
            window.removeEventListener('scroll', handlePosition);
            document.body.removeEventListener('click', handleClose);
        };
    }, []);


    // build external URL, including any flutter app version parameter, so other services know app version
    const buildExternalURL = (path) => (versionParam ? path.replace(/\/#\//, `/?v=${versionParam}/#/`) : path);

    return (
        <Wrapper theme={pos} isApp={isApp}>
            <QuestionButton
                type="button" onClick={() => setDisplayLayer(prev => !prev)}
                onMouseOver={() => hover = true} onMouseOut={() => hover = false}
            >
                <IconHelp />
            </QuestionButton>
            {displayLayer && (
                <BubbleLayer onMouseOver={() => hover = true} onMouseOut={() => hover = false}>
                    <ul>
                        <li><a href={footerLinks.support} target="_blank" rel="noreferrer">{localization['help-center']}</a></li>
                        {/* <li><a href={footerLinks.help} target="_blank" rel="noreferrer">{localization['footer-how-to-use']}</a></li> */}
                        {onClickManual && <li><button onClick={onClickManual}>{localization['analyzer-manual']}</button></li>}
                        <li>
                            {onClickFeedback ? <button type="button" onClick={onClickFeedback}>{localization['contact-us']}</button> : <a href={footerLinks.feedback} target="_blank" rel="noreferrer">{localization['contact-us']}</a>}
                        </li>
                        <li><a href={buildExternalURL(footerLinks.glossary)} target="_blank" rel="noreferrer">{localization['btn-glossary']}</a></li>
                    </ul>
                </BubbleLayer>
            )}
        </Wrapper>
    );
};

export default HelpButton;