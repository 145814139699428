import React from 'react';

const IconRocketDisable = () => (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.45575 19.8762C6.67312 19.3574 9.01407 13.8516 12.4264 10.0187L10.2175 9.56756C9.1131 9.35037 7.97537 9.7021 7.18916 10.4888L2.15407 15.5269C1.31767 16.3638 1.58583 17.7861 2.67361 18.2543L6.45575 19.8762Z" fill="#C4C4C4" />
        <path d="M9.77268 29.2174C9.93329 28.6544 9.95241 28.0605 9.82835 27.4883C9.54553 26.1058 8.38963 24.9862 6.99881 24.7475C6.42299 24.6417 5.82995 24.6797 5.27235 24.8582C4.71475 25.0367 4.20983 25.35 3.80244 25.7705C2.89252 26.6978 2.27393 29.541 1.94762 31.3837C1.80657 32.2039 2.54105 32.9153 3.35637 32.7483C5.18775 32.3634 8.00958 31.6428 8.90764 30.7157C9.31492 30.2951 9.61206 29.7804 9.77268 29.2174Z" fill="#C4C4C4" />
        <path fillRule="evenodd" clipRule="evenodd" d="M29.4525 2.23325C26.4997 1.7832 19.9753 2.04291 12.233 10.0358C9.47199 12.8861 7.38063 17.2066 6.49163 19.1614L6.4915 19.1617C6.04902 20.1356 6.30012 21.2596 7.03433 21.9708L12.4735 27.2394C13.2289 27.9711 14.3429 28.1624 15.2955 27.6939C17.2305 26.7552 21.4774 24.5121 24.2369 21.6633C31.9792 13.6705 32.031 7.14105 31.4871 4.20407L31.4872 4.20406L31.4854 4.1951C31.3883 3.70092 31.1424 3.24826 30.7806 2.89785C30.4189 2.54744 29.9586 2.31603 29.4616 2.23468L29.4616 2.23463L29.4525 2.23325Z" fill="#DFDFDF" />
        <path d="M19.886 10.1925C17.6775 10.2277 15.8994 12.0634 15.9345 14.2718C15.9697 16.4803 17.8054 18.2585 20.0139 18.2233C22.2224 18.1881 24.0005 16.3524 23.9653 14.144C23.9302 11.9355 22.0945 10.1573 19.886 10.1925Z" fill="#DFDFDF" stroke="#C4C4C4" strokeWidth="1.78051" />
        <path d="M14.3777 27.9408C14.8892 27.7068 20.3168 25.1897 24.0377 21.6556L24.5598 23.8488C24.8124 24.9456 24.4976 26.0941 23.7366 26.9053L18.8633 32.1001C18.0538 32.963 16.6237 32.7408 16.1207 31.6687L14.3777 27.9408Z" fill="#C4C4C4" />
        <path d="M21.9244 17.3063L21.4468 10.9225C21.8266 11.1671 22.7379 11.7291 23.3441 12.0202C24.1018 12.3841 23.7618 14.6453 23.7738 15.397C23.7834 15.9984 22.5449 16.9205 21.9244 17.3063Z" fill="#F4F4F4" />
        <path d="M19.886 10.1925C17.6775 10.2277 15.8994 12.0634 15.9345 14.2718C15.9697 16.4803 17.8054 18.2585 20.0139 18.2233C22.2224 18.1881 24.0005 16.3524 23.9653 14.144C23.9302 11.9355 22.0945 10.1573 19.886 10.1925Z" stroke="#C4C4C4" strokeWidth="1.78051" />
        <mask
            id="mask0_313_3991" maskUnits="userSpaceOnUse" x="6" y="2"
            width="26" height="26">
            <path fillRule="evenodd" clipRule="evenodd" d="M29.4526 2.2335C26.4998 1.78345 19.9753 2.04316 12.233 10.036C9.47205 12.8864 7.38069 17.2069 6.49168 19.1617L6.49156 19.162C6.04908 20.1359 6.30018 21.2598 7.03439 21.971L12.4735 27.2396C13.2291 27.9715 14.3433 28.1627 15.296 27.6939C17.2313 26.7551 21.4777 24.5121 24.2369 21.6636C31.9792 13.6707 32.0311 7.1413 31.4872 4.20432L31.4873 4.20431L31.4855 4.19535C31.3884 3.70117 31.1424 3.24851 30.7807 2.8981C30.4189 2.54769 29.9586 2.31628 29.4616 2.23493L29.4616 2.23488L29.4526 2.2335Z" fill="#87CBC8" />
        </mask>
        <g mask="url(#mask0_313_3991)">
            <ellipse
                cx="29.9545" cy="2.45806" rx="10.15" ry="6.3" transform="rotate(45.453 29.9545 2.45806)"
                fill="#C4C4C4" />
        </g>
    </svg>
);

export default IconRocketDisable;