import React from 'react';

const Global = ({ width, height, color }) => (
    <svg widwidth={width || '16'} height={height || '16'} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path stroke={color || '#3C3C3B'} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" d="M8.00001 14.6667C11.6819 14.6667 14.6667 11.6819 14.6667 8.00004C14.6667 4.31814 11.6819 1.33337 8.00001 1.33337C4.31811 1.33337 1.33334 4.31814 1.33334 8.00004C1.33334 11.6819 4.31811 14.6667 8.00001 14.6667Z" />
        <path stroke={color || '#3C3C3B'} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" d="M1.33334 8H14.6667" />
        <path stroke={color || '#3C3C3B'} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" d="M8.00001 1.33337C9.66753 3.15894 10.6152 5.52806 10.6667 8.00004C10.6152 10.472 9.66753 12.8411 8.00001 14.6667C6.33249 12.8411 5.38484 10.472 5.33334 8.00004C5.38484 5.52806 6.33249 3.15894 8.00001 1.33337V1.33337Z" />
    </svg>
);

export default Global;