import React from 'react';

const IconNavHelp = props => (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.9176 21.1932C15.9176 20.2159 16.1338 19.4077 16.566 18.7687C17.0171 18.1297 17.6655 17.4249 18.5113 16.6543C19.1315 16.0905 19.5825 15.6206 19.8645 15.2448C20.1652 14.8501 20.3155 14.4084 20.3155 13.9197C20.3155 13.2244 20.0336 12.6793 19.4698 12.2846C18.9247 11.8712 18.1917 11.6644 17.2708 11.6644C16.3875 11.6644 15.5981 11.8524 14.9027 12.2282C14.2261 12.5853 13.6529 13.0928 13.183 13.7506L9.77185 11.749C10.5612 10.5461 11.6231 9.61582 12.9575 8.95802C14.3107 8.30022 15.9082 7.97131 17.7501 7.97131C19.9114 7.97131 21.6405 8.43178 22.9373 9.3527C24.2529 10.2736 24.9108 11.5516 24.9108 13.1868C24.9108 13.9573 24.7792 14.6339 24.5161 15.2166C24.2717 15.7992 23.9616 16.2972 23.5857 16.7107C23.2287 17.1054 22.7588 17.5659 22.1762 18.0921C21.4808 18.7123 20.9733 19.2386 20.6538 19.6708C20.3343 20.0843 20.1746 20.5918 20.1746 21.1932H15.9176ZM18.0602 28.2693C17.2896 28.2693 16.6412 28.025 16.115 27.5363C15.6075 27.0289 15.3538 26.418 15.3538 25.7038C15.3538 24.9897 15.6075 24.3976 16.115 23.9278C16.6224 23.4391 17.2708 23.1948 18.0602 23.1948C18.8496 23.1948 19.498 23.4391 20.0054 23.9278C20.5129 24.3976 20.7666 24.9897 20.7666 25.7038C20.7666 26.418 20.5035 27.0289 19.9772 27.5363C19.4698 28.025 18.8308 28.2693 18.0602 28.2693Z"
            fill="#AFC0C0"/>
    </svg>
);

export default IconNavHelp;