import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import ReactHtmlParser from 'html-react-parser';
import { Link } from 'react-router-dom';

import { palette, zIndex } from '@mirinae/react-ui';
import { buildExternalURL } from '@mirinae/js-utils';

import { ReactComponent as IconCloseX } from '../assets/icon-close-fixedtopbanner.svg';
import { ReactComponent as IconLeftSparkling } from '../assets/star-left.svg';
import { ReactComponent as IconRightSparkling } from '../assets/star-right.svg';
import { explorerPaths, authPaths, membersPaths } from '@mirinae/shared/defines/paths';
import GlobalContext from '@mirinae/explorer/modules/defines/contexts';
import { currentTrialDays } from '@mirinae/shared/defines/datas';

const BannerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 68px;
    width: 100vw;
    min-height: 48px;
    background: linear-gradient(90deg, #275393 -70.26%, #293168 121.84%);
    opacity: 0.9;
    z-index: ${zIndex.header + 1};
    padding: 10px 69px 10px 52px;

    @media only screen and (max-width: 390px) {
        padding-left: 20px;
    }
`;

const BannerText = styled.div`
    color: ${palette.main.lighten[4]};
    margin: 0 24px 0 24px;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
  
    span {
        color: #FFE662;
    }
  
    strong {
        font-weight: 600;
    }
  
    small {
    }

    @media only screen and (max-width: 548px) {
        small {
            display: none;
        }
    }
`;

const Button = styled.button`
    position: absolute;
    right: 12px;
    width: 24px;
    height: 24px;
`;

const StartTrial = styled(Link)`
    font-weight: 500;
    font-size: 10px;
`;

const LearnMore = styled(Link)`
    display: inline;
    margin-left: 8px;
    text-decoration: underline;
`;

const AnalyzerTopFixedBanner = ({ localization, style, onCloseClick }) => {
    const [globalData] = useContext(GlobalContext);

    return (
        <BannerWrapper style={style}>
            <IconLeftSparkling />
            <BannerText>
                <strong>{ReactHtmlParser(localization['top-fixed-banner-title-1a'])}</strong><br />
                <StartTrial to={`${authPaths.signup}?redirect=${membersPaths.payment}`} reloadDocument >{localization['top-fixed-banner-title-2a'].replace('{trialDays}', currentTrialDays)}</StartTrial>
                <LearnMore to={membersPaths.plan} reloadDocument>{localization['learn-more']}</LearnMore>
            </BannerText>
            <Button
                height="24"
                type="button"
                onClick={(e) => { e.stopPropagation(); if (onCloseClick) onCloseClick(e); }}
            >
                <IconCloseX />
            </Button>
            <IconRightSparkling />
        </BannerWrapper>
    );
};

export default AnalyzerTopFixedBanner;
