import React from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
    &:hover {
        & path:first-child {
            stroke: #FF0000;
        }
        & path:last-child {
            stroke: #3C3C3B;
        }
    }
`;

const CloseBig = props => (
    <Wrapper>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 6L19 19" stroke="#3C3C3B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M19 6L6 19" stroke="#FF0000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </Wrapper>
);

export default CloseBig;