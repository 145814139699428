import React from 'react';

const IconMusic = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="32" viewBox="0 0 24 32" fill="none">
        <g clip-path="url(#clip0_1765_37190)">
            <path d="M24.0927 0.225063C23.931 0.109295 23.7434 0.0349096 23.5463 0.00841209C23.3492 -0.0180854 23.1486 0.00411185 22.962 0.0730627L22.97 0.0703961L8.20071 5.32106C7.96172 5.40649 7.75495 5.56363 7.60865 5.77103C7.46235 5.97842 7.38368 6.22593 7.38338 6.47973V23.5997C6.62857 23.1873 5.78217 22.9714 4.92205 22.9717C3.67377 22.9214 2.4548 23.3661 1.53285 24.2091C0.610892 25.0522 0.0592667 26.2253 -0.00195312 27.4731V27.4837C0.0568676 28.7351 0.608667 29.9123 1.53284 30.758C2.45701 31.6037 3.6784 32.0492 4.93005 31.9971H4.92071C6.16899 32.0474 7.38663 31.6027 8.30858 30.7597C9.23053 29.9166 9.78216 28.7435 9.84338 27.4957V10.3077L22.15 6.0104V17.9744C21.3948 17.5632 20.5486 17.3477 19.6887 17.3477H19.6874C18.4391 17.297 17.2215 17.7417 16.2996 18.5848C15.3778 19.428 14.8266 20.6013 14.766 21.8491V21.8597C14.8245 23.1112 15.3762 24.2886 16.3005 25.1344C17.2247 25.9802 18.4463 26.4255 19.698 26.3731H19.6887L19.8287 26.3757C20.9049 26.3757 21.9503 26.0163 22.7989 25.3544C23.6474 24.6925 24.2506 23.7662 24.5127 22.7224L24.5194 22.6891C24.5789 22.5453 24.6101 22.3914 24.6114 22.2357V1.2304C24.6116 1.03409 24.5647 0.84059 24.4747 0.666131C24.3847 0.491673 24.2541 0.341338 24.094 0.227729L24.09 0.225063H24.0927Z" fill="#C4C4C4"/>
        </g>
        <defs>
            <clipPath id="clip0_1765_37190">
            <rect width="24" height="32" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);

export default IconMusic;