import { findIndex, reject } from 'lodash';
import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import DraggableComponent from 'react-draggable';
import styled from 'styled-components/macro';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useNavigate, useLocation } from 'react-router-dom';

import { zIndex, palette, classes, size, breakPoint } from '@mirinae/react-ui';
import { PlaylistContext } from '@mirinae/shared/contexts/playlist';
import { lyricExporerPaths } from '@mirinae/defines/paths';
import { os } from '@mirinae/js-utils/utils/userAgent';

import { ReactComponent as IconPlay } from '@mirinae/lyric-explorer/components/assets/icon-play.svg';
import { ReactComponent as IconPause } from '@mirinae/lyric-explorer/components/assets/icon-pause.svg';
import { ReactComponent as IconSongNext } from '@mirinae/lyric-explorer/components/assets/icon-song-next.svg';
import { ReactComponent as IconPlaylist } from '@mirinae/lyric-explorer/components/assets/icon-playlist.svg';
import { ReactComponent as IconMenu } from '@mirinae/lyric-explorer/components/assets/icon-menu.svg';
import { ReactComponent as IconMore } from '@mirinae/lyric-explorer/components/assets/icon-more.svg';
import { ReactComponent as IconLyrics } from '@mirinae/lyric-explorer/components/assets/icon-lyrics.svg';
import { ReactComponent as IconArrawRight } from '@mirinae/lyric-explorer/components/assets/icon-arrow-right.svg';
import { ReactComponent as IconParkOutlineRecord } from '@mirinae/lyric-explorer/components/assets/icon-park-outline-record.svg';
import { ReactComponent as IconArtist } from '@mirinae/lyric-explorer/components/assets/icon-artist.svg';
import { ReactComponent as IconTrash } from '@mirinae/lyric-explorer/components/assets/icon-trash.svg';
import IconClose from '@mirinae/lyric-explorer/components/assets/icon-close.svg';
import { IconCheckCircle } from 'apps/shared/components/ui';
import IconMusic from '../assets/IconMusic';
import AnimatedMusicBar from './AnimatedMusicBar';

const isMobile = !os.isOthers;

const Wrapper = styled.div`
    position: fixed;
    z-index: ${zIndex.header + 1};
    display: ${props => props.visible ? 'block' : 'none'}
`;

const Thumb = styled.div`
    position: absolute;
    left: calc(100vw - 50px);
    top: calc(${size.header} + 15px);
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 55px;
    border-radius: 5px;
    box-shadow: 4px 4px 15px 2px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    cursor: pointer;
    z-index: ${zIndex.header + 1};

    img {
        width: 100%;
        height: 100%;
        pointer-events: none;
    }

    svg {
        position: absolute;
    }
`;

const Controls = styled.div`
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0 10px;
    height: 55px;
    padding: 12px 0;
    // border: 1px solid ${palette.gray[7]};
    box-shadow: 0 4px 10px rgba(196, 196, 196, 0.25);
    border-radius: 5px;
    background-color: ${palette.primary.white};
    width: 0;
    right: 60px;
    top: calc(${size.header} + 15px);
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    overflow: hidden;
    z-index: ${zIndex.header + 1};

    &.on {
        width: 265px;
        animation-name: fadein;
    }

    &.off {
        width: 0;
        animation-name: fadeout;
    }

    @keyframes fadein {
        0% {
            width: 0px;
        }
        100% {
            width: 265px;
        }
    }

    @keyframes fadeout {
        0% {
            width: 265px;
        }
        100% {
            width: 0;
        }
    }
`;
const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 135px;
    width: 135px;
    margin: 0 0 0 15px;
`;
const Title = styled.div`
    color: var(--primary-black, #3C3C3B);
    font-family: SUIT;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 12px */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
const Name = styled.div`
    color: var(--primary-black, #3C3C3B);
    font-family: SUIT;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 11px */
    margin-top: 5px;
`;
const ControlButton = styled.button`
    min-width: 24px;
    min-height: 24px;
    font-size: 0;
    &.active,
    :hover:not(:disabled) {
        svg path {
            fill: ${palette.main.darken[1]};
        }
        svg rect {
            fill: ${palette.main.darken[1]};
        }
    }
    :disabled {
        svg path {
            fill: ${palette.gray[5]};
        }
        svg rect {
            fill: ${palette.gray[5]};
        }
    }
`;

const Tag = styled.div`
    position: absolute;
    width: 30px;
    height: 40px;
    left: -30px;
    top: 11px;
    border-radius: 5px 0 0 5px;
    box-shadow: rgba(0, 0, 0, 0.15) -2px 2px 5px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: white;
`;

const PlaylistWrapper = styled.div`
    position: fixed;
    display: block;
    right: -290px;
    top: ${size.header};
    z-index: ${zIndex.header + 2};
    user-select: none;
    width: 260px;
    height: calc(100vh - ${size.header});
    background: white;
    box-shadow: rgba(0, 0, 0, 0.15) -2px 2px 14px 0px;
    animation-duration: 0.5s;
    animation-iteration-count: 1;

    @media only screen and (max-width: ${breakPoint.common.lg}) {
        top: 0;
        height: 100vh;
        width: 100vw;
        right: -100vw;
        box-shadow: initial;
    }

    &.on {
        right: 0px;
        animation-name: slide-in;
    }

    &.off {
        right: -290px;
        animation-name: slide-out;

        @media only screen and (max-width: ${breakPoint.common.lg}) {
            right: -100vw;
        }
    }

    @keyframes slide-in {
        0% {
            right: -290px;

            @media only screen and (max-width: ${breakPoint.common.lg}) {
                right: -100vw;
            }
        }
        100% {
            right: 0px;
        }
    }

    @keyframes slide-out {
        0% {
            right: 0px;
        }
        100% {
            right: -290px;

            @media only screen and (max-width: ${breakPoint.common.lg}) {
                right: -100vw;
            }
        }
    }
`;

const Content = styled.div`
`;

const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;

    ${Title} {
        padding: 19px 20px;
        color: var(--Primary-Black, #3C3C3B);
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 14px */
        width: 100%;

        @media only screen and (max-width: ${breakPoint.common.lg}) {
            text-align: center;
        }
    }
`;

const CloseButton = styled.button`
    width: 24px;
    height: 24px;
    background: url(${IconClose}) no-repeat 50%;
    font-size: 0;
`;

const SongItem = styled.div`
    display: flex;
    height: 70px;
    padding: 10px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    background: ${props => props.active ? '#FBFBFB' : 'initial'};

    ${Thumb} {
        cursor: default;
        width: 35px;
        height: 35px;
        left: initial;
        top: initial;
        position: relative;
    }

    ${InfoWrapper} {
        min-width: 97px;
        width: 97px;
        margin: 0;
        cursor: pointer;

        @media only screen and (max-width: ${breakPoint.common.lg}) {
            width: calc(100vw - 167px);
        }

        ${Title} {
            padding: 0;
        }
    }
`;

const EmptyList = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--Primary-Black, #3C3C3B);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 14px */
    height: calc(100vh - 52px);
`;

const Handle = styled.div`
    cursor: pointer;
    width: 24px;
`;

const MenuWrapper = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: ${zIndex.header + 3};
    pointer-events: none;
`;
const MenuOverlay = styled.div`
    display: ${props => props.visible ? 'block' : 'none'};
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    pointer-events: all;
`;

const Menu = styled.div`
    position: fixed;
    right : -200px;
    z-index: ${zIndex.header + 3};
    padding: 5px 0;
    border-radius: 10px;
    border: 2px solid rgba(255, 255, 255, 0.30);
    background: #FFF;
    box-shadow: 4px 2px 16px 0px rgba(159, 188, 186, 0.30);
    pointer-events: all;
    animation-duration: 0.5s;
    animation-iteration-count: 1;

    &.on {
        right: 24px;
        animation-name: menuSlideLeft;
    }

    &.off {
        right: -200px;
        animation-name: menuSlideRight;
    }

    @media only screen and (max-width: ${breakPoint.common.md}) {
        bottom: -248px;
        width: 100vw;
        right: 0;
        border-radius: 0;
        padding: 0;
    
        &.on {
            right: 0;
            bottom: 0px;
            animation-name: menuSlideUp;
        }

        &.off {
            right: 0;
            animation-name: menuSlideDown;
        }
    }

    @keyframes menuSlideUp {
        0% {
            bottom: -248px;
        }
        100% {
            bottom: 0px;
        }
    }

    @keyframes menuSlideDown {
        0% {
            bottom: 0px;
        }
        100% {
            bottom: -248px;
        }
    }

    @keyframes menuSlideLeft {
        0% {
            right: -200px;
        }
        100% {
            right: 24px;
        }
    }

    @keyframes menuSlideRight {
        0% {
            right: 24px;
        }
        100% {
            right: -200px;
        }
    }
    
    ${SongItem} {
        padding: 15px 25px;

        ${CloseButton} {
            margin-left: auto;
            margin-right: -10px;
        }
    }
    ${InfoWrapper} {
        width: calc(100vw - 167px);
    }
`;

const MenuItem = styled.div`
    padding: 8px 20px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    color: var(--Primary-Black, #3C3C3B);    
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 200% */
    cursor: pointer;

    @media only screen and (max-width: ${breakPoint.common.lg}) {
        padding: 15px 25px;
    }

    &:hover {
        color: var(--Contents-Explorer-Default, #8868BD);

        svg circle {
            stroke: #8868BD;
        }
        svg path {
            stroke: #8868BD;
        }
    }
`;

const MessageWrapper = styled.div`
    pointer-events: none;
    z-index: ${zIndex.tutorial + 1};
    position: ${os.isOthers ? 'fixed' : 'fixed'};
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    & > div {
        max-width: 368px;
    }
`;
const TextBubbleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: fit-content;
    height: 50px;
    padding: 0 50px;
    border-radius: 25px;
    white-space: nowrap;
    background-color: #A0C991;
    animation: playlistSlideDown 2s ease-out forwards;

    @keyframes playlistSlideDown {
        0% { bottom: 0; }
        4% { bottom: 100px; }
        90% { bottom: 100px; opacity: 1; }
        100% { bottom: 100px; opacity: 0; }
    }

    ${os.isOthers ? `
        left: 50%;
        transform: translateX(-50%);
    ` : `
        left: 11px;
        right: 11px;
        margin: auto;
    `}
`;
const TextInner = styled.div`
    height: 16px;
    color: #FBFBFB;
    font-size: 14px;

    svg {
        vertical-align: middle;
        margin: 0 10px 2px 0;
    }
`;

// fake data generator(가짜 데이터 제너레이터)
const getItems = (count) => Array.from({length: count}, (v, k) => k).map(k => ({
    id: `item-${k}`,
    content: `item ${k}`
}));
  
// a little function to help us with reordering the result(결과 재정렬을 돕는 함수)
const reorder =  (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
};
  
// using some little inline style helpers to make the app look okay(보기좋게 앱을 만드는 인라인 스타일 헬퍼)
const getItemStyle = (draggableStyle, isDragging) => ({
    // some basic styles to make the items look a bit nicer(아이템을 보기 좋게 만드는 몇 가지 기본 스타일)
    userSelect: 'none',
  
    // change background colour if dragging(드래깅시 배경색 변경)
    background: isDragging ? 'grey' : 'white',
  
    // styles we need to apply on draggables(드래그에 필요한 스타일 적용)
    ...draggableStyle
});

let t;

const Playlist = () => {
    const playlist = useContext(PlaylistContext);
    const [top, setTop] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    const $bar = useRef();
    const $list = useRef();
    const $thumb = useRef();
    const $menu = useRef();
    const navigate = useNavigate();
    const [dragStartPos, setDragStartPos] = useState({ x: 0, y: 0 });
    const [isDragging, setDragging] = useState(false);
    const [visible, setVisible] = useState(true);
    const location = useLocation();

    useEffect(() => {
        setVisible(!window.location.pathname.startsWith(lyricExporerPaths.song));
        if ($bar.current) {
            $bar.current.classList.remove('on');
            $bar.current.classList.remove('off');
        }
        if ($list.current) {
            $list.current.classList.remove('on');
            $list.current.classList.remove('off');
        }
        if ($menu.current) {
            $menu.current.classList.remove('on');
            $menu.current.classList.remove('off');
        }
        playlist.update({ mode: 'thumb' });
    }, [location.pathname]);

    useEffect(() => {
        clearTimeout(t);
        t = setTimeout(() => playlist.showMessage(false), 2500);
    }, [playlist.message]);

    const dragStart = (e) => {
        const pos = e.touches ? e.touches[0] : e;
        setDragStartPos({ x: pos.screenX, y: pos.screenY });
        setDragging(false);
    };
    
    const dragStop = (e) => {
        const pos = e.touches ? e.touches[0] || e.changedTouches[0] : e;
        const dragY = Math.abs(dragStartPos.y - pos.screenY);
        if (dragY < 5) {
            console.log(`click with drag of ${dragY}`);
            // onClick functionality here
            setDragging(false);

            if (e.touches) {
                handleTogglePlayBar(e);
            }
        } else {
            console.log(`click cancelled with drag of ${dragY}`);
            setDragging(true);
        }
    };

    const handleTogglePlayBar = e => {
        e.stopPropagation();

        if (isDragging) return;

        if (playlist.mode === 'thumb' && $bar.current) {
            const transform = $thumb.current.style.transform;
            $bar.current.style.transform = transform;
            $bar.current.classList.remove('off');
            $bar.current.classList.add('on');
        } else if (playlist.mode === 'bar' && $bar.current) {
            const transform = $thumb.current.style.transform;
            $bar.current.style.transform = transform;
            $bar.current.classList.remove('on');
            $bar.current.classList.add('off');
        }
        playlist.update({ mode: playlist.mode === 'thumb' ? 'bar' : 'thumb' });
    }

    const handlePlaylist = (e) => {
        e.stopPropagation();

        if ($bar.current) {
            $bar.current.classList.remove('on');
            $bar.current.classList.remove('off');
        }
        if ($list.current) {
            $list.current.classList.remove('off');
            $list.current.classList.add('on');
        }
        playlist.update({ mode: 'list' });
    }

    const handleHidePlaylist = e => {
        e.stopPropagation();

        if ($list.current) {
            $list.current.classList.remove('on');
            $list.current.classList.add('off');
        }
        playlist.update({ mode: 'thumb' });
    }

    const handleTogglePlay = (e) => {
        e.stopPropagation();

        if (playlist.isPlaying()) {
            playlist.pause();
        } else {
            playlist.play();
        }
    }

    const onDragEnd = (result) => {
        // dropped outside the list(리스트 밖으로 드랍한 경우)
        if(!result.destination) {
            return;
        }
    
        const list = reorder(
            playlist.data.list,
            result.source.index,
            result.destination.index
        );
    
        const newIndex = findIndex(list, { _id: playlist.data.list[playlist.index]._id });
        playlist.update({ data: { ...playlist.data, list }, index: newIndex });
        playlist.save(list, false);
    }    

    const gotoSongPage = (item, e) => {
        handleCloseMenu(e);
        handleHidePlaylist(e);
        navigate(`${lyricExporerPaths.song}/${item._id}`);
    }

    const gotoArtistPage = (item, e) => {
        handleCloseMenu(e);
        handleHidePlaylist(e);
        navigate(`${lyricExporerPaths.artist}/${item.artist.en}`);
    }

    const removeSong = (item, e) => {
        handleCloseMenu(e);
        const list = reject(playlist.data.list, { _id: item._id });
        playlist.update({
            index: list.length > 0 ? 0 : -1,
            data: { ...playlist.data, list }
        });
        playlist.save(list);
        playlist.showMessage('Removed from my playlist.');
    }

    const playSong = (index, e) => {
        e.stopPropagation();
        if (playlist.isPlaying(index)) {
            playlist.pause(index);
        } else {
            playlist.skipTo(index);
        }
    }

    const playNextSong = e => {
        e.stopPropagation();
        playlist.skip('next');
    }

    const handleMoreButton = (item, e) => {
        e.stopPropagation();
        const rc = e.target.getBoundingClientRect();
        // console.log('handleMoreButton:', rc);
        setTop(rc.y + rc.height + 10);
        setSelectedItem(item);

        if ($menu.current) {
            $menu.current.classList.remove('off');
            $menu.current.classList.add('on');
        }
    }

    const handleCloseMenu = e => {
        console.log('handleCloseMenu:', selectedItem);
        e.stopPropagation();

        if (selectedItem) {
            if ($menu.current) {
                $menu.current.classList.remove('on');
                $menu.current.classList.add('off');
            }
    
            setTimeout(() => {
                setSelectedItem(null);
            }, 500);
        }

        if ($bar.current && $bar.current.classList.contains('on')) {
            handleTogglePlayBar(e);
        }
    }

    useEffect(() => {
        document.body.addEventListener('click', handleCloseMenu);
        return () => {
            document.body.removeEventListener('click', handleCloseMenu);
        };
    }, [selectedItem, playlist]);

    return (
        <div>
            <Wrapper visible={visible}>
                <DraggableComponent axis="y" onStart={dragStart} onStop={dragStop}>
                    <Thumb ref={$thumb} onClick={handleTogglePlayBar}>
                        { playlist.index >= 0 && playlist.data?.list[playlist.index] && (
                            <img src={playlist.data.list[playlist.index].albumCover.url} />
                        )}
                        { playlist.isPlaying() && <AnimatedMusicBar size={55} /> }
                        { playlist.length() === 0 && <IconMusic /> }
                    </Thumb>
                </DraggableComponent>
                <Controls ref={$bar}>
                    <InfoWrapper>
                        <Title>{playlist.length() === 0 ? 'Not playing' : playlist.data.list[playlist.index].title.en}</Title>
                        {playlist.index >= 0 && (<Name>{playlist.data.list[playlist.index].artist?.en}</Name>)}
                    </InfoWrapper>
                    <ControlButton type="button" theme="play" disabled={playlist.index === -1} onClick={handleTogglePlay}>
                        {playlist.isPlaying() ? <IconPause /> : <IconPlay />}
                    </ControlButton>
                    <ControlButton type="button" disabled={playlist.index + 1 === playlist.data?.list?.length} onClick={playNextSong}>
                        <IconSongNext />
                    </ControlButton>
                    <ControlButton type="button" onClick={handlePlaylist}>
                        <IconPlaylist />
                    </ControlButton>
                </Controls>
                <PlaylistWrapper ref={$list}>
                    { !isMobile && (
                        <Tag onClick={handleHidePlaylist}>
                            <IconArrawRight />
                        </Tag>
                    )}
                    <Content>
                        <TitleWrapper>
                            { isMobile && <CloseButton onClick={handleHidePlaylist} /> }
                            <Title>My Playlist</Title>
                        </TitleWrapper>
                        {playlist.length() === 0 ? (
                            <EmptyList>No songs</EmptyList>
                        ) : (
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="playlist">
                                    {provided => (
                                        <div {...provided.droppableProps} ref={provided.innerRef}>
                                            {playlist.data?.list?.map((item, index) => (
                                                <Draggable key={item._id} draggableId={item._id} index={index}>
                                                    {(provided, snapshot) => (
                                                        <SongItem
                                                            ref={provided.innerRef}
                                                            style={getItemStyle(provided.graddableStyle, snapshot.isDragging)}
                                                            active={index === playlist.index}
                                                            {...provided.draggableProps}
                                                        >
                                                            <Handle
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <IconMenu />
                                                            </Handle>
                                                            <Thumb>
                                                                <img src={item.albumCover.url} />
                                                                { playlist.isPlaying(index) && <AnimatedMusicBar size={35} /> }
                                                            </Thumb>
                                                            <InfoWrapper onClick={e => playSong(index, e)}>
                                                                <Title>{item.title.en}</Title>
                                                                <Name type="button">{item.artist?.en}</Name>
                                                            </InfoWrapper>
                                                            <ControlButton onClick={e => gotoSongPage(item, e)}>
                                                                <IconLyrics />
                                                            </ControlButton>
                                                            <ControlButton onClick={e => handleMoreButton(item, e)}>
                                                                <IconMore />
                                                            </ControlButton>
                                                        </SongItem>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        )}
                    </Content>
                </PlaylistWrapper>
                <MenuWrapper>
                    { isMobile && (
                        <MenuOverlay visible={Boolean(selectedItem)} onClock={handleCloseMenu} />
                    )}
                    <Menu ref={$menu} style={{ top: isMobile ? 'initial' : top }}>
                        { isMobile && (
                            <SongItem>
                                <Thumb>
                                    <img src={selectedItem?.albumCover?.url} />
                                    { playlist.isPlaying(selectedItem) && <AnimatedMusicBar size={35} /> }
                                </Thumb>
                                <InfoWrapper>
                                    <Title>{selectedItem?.title?.en}</Title>
                                    <Name>{selectedItem?.artist?.en}</Name>
                                </InfoWrapper>
                                <CloseButton onClick={handleCloseMenu} />
                            </SongItem>
                        )}
                        <MenuItem onClick={e => gotoSongPage(selectedItem, e)}>
                            <IconParkOutlineRecord />
                            Go to the song
                        </MenuItem>
                        <MenuItem onClick={e => gotoArtistPage(selectedItem, e)}>
                            <IconArtist />
                            Go to the artist
                        </MenuItem>
                        <MenuItem onClick={e => removeSong(selectedItem, e)}>
                            <IconTrash />
                            Remove
                        </MenuItem>
                    </Menu>
                </MenuWrapper>
            </Wrapper>
            {playlist.message && (
                <MessageWrapper>
                    <TextBubbleWrapper>
                        {/* Link to this page copied */}
                        <TextInner>
                            <IconCheckCircle />
                            {playlist.message}
                        </TextInner>
                    </TextBubbleWrapper>
                </MessageWrapper>
            )}
        </div>
    );
}

export default Playlist;
