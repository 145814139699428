import React from 'react';

const IconLessonsActive = () => (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M19.25 3.15V5.2885C19.25 6.2552 18.4667 7.0385 17.5 7.0385C16.5333 7.0385 15.75 6.2552 15.75 5.2885V3.15C15.75 2.1833 16.5333 1.4 17.5 1.4C18.4667 1.4 19.25 2.1833 19.25 3.15Z" fill="#48A9A6" />
        <path fillRule="evenodd" clipRule="evenodd" d="M32.6838 25.4721H2.31641V5.53119C2.31641 4.43989 3.20121 3.55579 4.29181 3.55579H30.7084C31.799 3.55579 32.6838 4.43989 32.6838 5.53119V25.4721Z" fill="#C5EAE7" />
        <path fillRule="evenodd" clipRule="evenodd" d="M2.31641 25.472H32.6838V22.322H2.31641V25.472Z" fill="#87CBC8" />
        <path fillRule="evenodd" clipRule="evenodd" d="M29.9079 32.7883L25.5252 25.5818C25.3957 25.3683 25.1654 25.2388 24.9162 25.2388H23.3188C22.7623 25.2388 22.4207 25.8471 22.7091 26.3224L24.016 28.4714C24.3051 28.9467 23.9635 29.5557 23.407 29.5557H11.5938C11.0373 29.5557 10.6957 28.9467 10.9841 28.4714L12.291 26.3224C12.5801 25.8471 12.2385 25.2388 11.682 25.2388H10.0846C9.83536 25.2388 9.60506 25.3683 9.47556 25.5818L5.09286 32.7883C4.80376 33.2636 5.14536 33.8719 5.70186 33.8719H7.29926C7.54846 33.8719 7.77946 33.7424 7.90826 33.5296L8.94636 31.823C9.07586 31.6102 9.30686 31.4807 9.55536 31.4807H25.4454C25.6939 31.4807 25.9249 31.6102 26.0544 31.823L27.0918 33.5296C27.2213 33.7424 27.4523 33.8719 27.7015 33.8719H29.2989C29.8547 33.8719 30.197 33.2636 29.9079 32.7883Z" fill="#87CBC8" />
        <path fillRule="evenodd" clipRule="evenodd" d="M32.5502 28.0096H2.4502C1.4835 28.0096 0.700195 27.2263 0.700195 26.2596C0.700195 25.2929 1.4835 24.5096 2.4502 24.5096H32.5502C33.5169 24.5096 34.3002 25.2929 34.3002 26.2596C34.3002 27.2263 33.5169 28.0096 32.5502 28.0096Z" fill="#48A9A6" />
        <path fillRule="evenodd" clipRule="evenodd" d="M12.0479 12.3056H22.9525V10.8979H12.0479V12.3056Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M12.0479 15.5033H22.9525V14.0963H12.0479V15.5033Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M12.0479 18.7016H17.0081V17.2939H12.0479V18.7016Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M20.3444 21.6364C19.9685 21.2605 19.9685 20.6508 20.3444 20.2756L32.6567 7.96264C33.0326 7.58674 33.6423 7.58674 34.0182 7.96264C34.3941 8.33854 34.3941 8.94824 34.0182 9.32414L21.7052 21.6364C21.33 22.0123 20.7203 22.0123 20.3444 21.6364Z" fill="#48A9A6" />
        <path fillRule="evenodd" clipRule="evenodd" d="M21.9795 18.6406L20.345 20.2751C19.9683 20.651 19.9683 21.2607 20.345 21.6366C20.7209 22.0125 21.3299 22.0125 21.7058 21.6366L23.3403 20.0014L21.9795 18.6406Z" fill="#00D7D0" />
    </svg>
);

export default IconLessonsActive;