import React from 'react';

const IconNavFeedbackActive = props => (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.63005 18.6372L11.2547 20.2237L21.7001 14.5254L15.4001 21.8754L24.4301 25.9872C24.5845 26.0548 24.7529 26.0842 24.9212 26.073C25.0894 26.0618 25.2524 26.0103 25.3965 25.9227C25.5406 25.8352 25.6615 25.7142 25.7489 25.5701C25.8364 25.426 25.8879 25.2629 25.899 25.0947L26.949 9.34465C26.9607 9.16236 26.9247 8.98016 26.8446 8.81598C26.7645 8.6518 26.6431 8.51131 26.4922 8.40833C26.3413 8.30534 26.1662 8.24341 25.9842 8.22864C25.8021 8.21386 25.6193 8.24674 25.4538 8.32405L7.6038 16.7241C7.42172 16.8104 7.26828 16.9473 7.16174 17.1184C7.05519 17.2894 7.00002 17.4875 7.00278 17.689C7.00555 17.8905 7.06613 18.087 7.17734 18.2551C7.28854 18.4232 7.44567 18.5558 7.63005 18.6372Z"
            fill="#48A9A6"/>
        <path d="M18.3148 25.7435L13.3 28.1753V23.4041L18.3148 25.7435Z" fill="#87CBC8"/>
    </svg>
);

export default IconNavFeedbackActive;