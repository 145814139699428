import React from 'react';
import styled from 'styled-components/macro';

import { palette, size, breakPoint, fontDefault } from '@mirinae/react-ui';
import { ExternalLinks } from '@mirinae/js-utils';

// import LangSelector from './elements/LangSelector';
// import { ImgFooter } from './assets/ImageBase64';
import HelpButton from './elements/HelpButton';
import { explorerPaths } from '@mirinae/defines/paths';

const Footer = styled.footer`
    min-width: ${size.mobileWidth};
    padding: 50px 0 50px 100px;
    background-color: ${palette.gray[7]};
    ${props => (!props.isApp && `
        @media only screen and (max-width: ${breakPoint.common.lg}) {
            padding-bottom: 120px;
            padding-left: 0px;
        }
    `)}
`;
const FooterInner = styled.div`
    position: relative;
    max-width: 1080px;
    margin: auto;
    padding: 0 30px 80px;

    @media only screen and (max-width: ${breakPoint.common.lg}) {
        padding-bottom: 0;
    }
    @media only screen and (max-width: 600px) {
        padding:  ${props => (props.nolang ? '0' : '50px')} 20px 0;
    }
`;
const Columns = styled.div`
    display: flex;
    gap: 0 60px;
    @media only screen and (max-width: ${breakPoint.common.lg}) {
        gap: 0 20px;
    }
    @media only screen and (max-width: ${breakPoint.common.md}) {
        flex-direction: column;
        gap: 30px 20px;
    }
`;
const ColumnWrap = styled.div`
    display: flex;
    gap: 0 60px;
    @media only screen and (max-width: ${breakPoint.common.lg}) {
        gap: 0 20px;
    }
    @media only screen and (max-width: ${breakPoint.common.md}) {
        gap: 0 30px;
    }
`;
const Column = styled.div`
    display: flex;
    flex-direction: column;
    gap: 25px 0;
    min-width: 95px;
    max-width: 425px;
    color: ${palette.gray[3]};
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;

    strong {
        display: block;
        color: ${palette.primary.black};
        font-size: 16px;
        font-weight: 600;
        white-space: nowrap;
    }
    ul {
        display: flex;
        flex-direction: column;
        gap: 15px; 0;
    }
    button {
        color: inherit;
        font-weight: inherit;
        font-size: inherit;
        font-family: inherit;
        text-align: left;
        margin: 0;
        padding: 0;
        border: 0;
    }
    button:hover,
    a:hover {
        text-decoration: underline;
    }

    @media only screen and (max-width: ${breakPoint.common.lg}) {
        max-width: 170px;
    }
    @media only screen and (max-width: 480px) {
        max-width: fit-content;
    }
    @media only screen and (max-width: ${breakPoint.common.sm}) {
        &:nth-child(3) {
            margin-left: 0;
        }
        & > div {
            text-align: center;
        }
    }
`;
const SnsButtons = styled.div`
    display: flex;
    gap: 0 25px;
    @media only screen and (max-width: ${breakPoint.common.lg}) {
        gap: 0 20px;
    }
`;
const SnsButton = styled.a`
    flex-shrink: 0;
    display: block;
    width: 25px;
    height: 25px;
    text-indent: -999px;
    overflow: hidden;
    font-size: 0;
    pointer-events: fill;
    background-image: url('/assets/gnb/footer-image.png');
    background-repeat: no-repeat;
    background-size: 157px auto;
    background-position: ${props => (props.theme === 'f' ? '-25px' : props.theme === 'y' ? '-50px' : props.theme === 'i' ? '-75px' : '0')} -150px;

    :hover {
        background-position: ${props => (props.theme === 'f' ? '-25px' : props.theme === 'y' ? '-50px' : props.theme === 'i' ? '-75px' : '0')} -175px;
    }

    svg {
        pointer-events: none;
    }
`;
const Banners = styled.div`
    position: absolute;
    right: 20px;
    bottom: 0;
    display: flex;

    @media only screen and (max-width: ${breakPoint.common.lg}) {
        position: static;
        justify-content: center;
        margin-top: 40px;
    }
`;
const Banner = styled.a`
    display: block;
    width: ${props => (props.theme === 'chrome' ? '157px' : props.theme === 'appStore' ? '120px' : '136px')};
    height: 40px;
    margin-right: 8px;
    text-indent: -999px;
    overflow: hidden;
    background-image: url('/assets/gnb/footer-image.png');
    background-repeat: no-repeat;
    background-size: 157px auto;
    background-position: 0 ${props => (props.theme === 'chrome' ? '0' : props.theme === 'appStore' ? '-50px' : '-100px')};

    &:last-child {
        margin-right: 0;
    }
    ${props => (props.theme === 'chrome' && `
        @media only screen and (max-width: ${breakPoint.common.lg}) {
            display: none;
        }
    `)}
`;
const Copyright = styled.div`
    position: absolute;
    left: 30px;
    bottom: 0;
    color: ${palette.gray[3]};
    font-size: 14px;
    font-weight: 400;
    font-family: ${fontDefault};

    @media only screen and (max-width: ${breakPoint.common.lg}) {
        position: static;
        margin-top: 20px;
        text-align: center;
    }
`;
const LanguageSelector = styled.div`
    position: absolute;
    right: 30px;
    top: 0;
    @media only screen and (max-width: 600px) {
        position: absolute;
        left: 20px;
        right: initial;
    }
`;

const FooterComponent = ({
    isApp = false,
    versionParam = '',
    localization = {
        company: 'Company',
        'about-us': 'About us',
        'privacy-policy': 'Privacy policy',
        'terms-of-use': 'Terms of Use',
        'blog': 'Blog',
        'support': 'Support',
        'help-center': 'Help Center',
        'contact-us': 'Contact Us',
        // 'footer-how-to-use': 'How to use this site',
        // 'fb-feedback': 'Feedback',
        'social-media': 'Social Media',
        'footer-social-media-text': 'Follow us on social media to be the first to know about new features, and events.',
        'analyzer-manual': 'Analyzer Manual',
        'btn-glossary': 'Glossary',

    },
    footerLinks = {
        company: ExternalLinks.mirinaeCompany,
        privacyPolicy: ExternalLinks.PrivacyPolicy,
        termsOfUse: ExternalLinks.TermsAndConditions,
        support: ExternalLinks.support,
        facebook : ExternalLinks.sns.facebook,
        instagram: ExternalLinks.sns.instagram,
        twitter: ExternalLinks.sns.twitter,
        youtube: ExternalLinks.sns.youtube,
        appStore: ExternalLinks.app.apple,
        playStore: ExternalLinks.app.playStore,
        blog: ExternalLinks.blog,
        // chrome: ExternalLinks.chromeExtensionMarket,
        feedback: explorerPaths.userFeedback,
        glossary: explorerPaths.glossary,
        // help: ExternalLinks.help,
    },
    langsList,
    langCode,
    onClickSelectLang,
    onClickManual = null,
    onClickFeedback,
}) => (
    <div style={{ position: 'relative' }}>
        <HelpButton isApp={isApp} versionParam={versionParam} footerLinks={footerLinks} localization={localization} onClickManual={onClickManual} onClickFeedback={onClickFeedback} />
        <Footer isApp={isApp}>
            <FooterInner nolang={!langsList || !langCode}>
                <Columns>
                    <ColumnWrap>
                        <Column>
                            <strong>{localization.company}</strong>
                            <ul>
                                <li><a href={footerLinks.company} target="_blank" rel="noreferrer">{localization['about-us']}</a></li>
                                <li><a href={footerLinks.privacyPolicy} target="_blank" rel="noreferrer">{localization['privacy-policy']}</a></li>
                                <li><a href={footerLinks.termsOfUse} target="_blank" rel="noreferrer">{localization['terms-of-use']}</a></li>
                                <li><a href={footerLinks.blog} target="_blank" rel="noreferrer">{localization['blog']}</a></li>
                                {/* <li>{localization.blog}</li> */}
                            </ul>
                        </Column>
                        <Column>
                            <strong>{localization.support}</strong>
                            <ul>
                                <li><a href={footerLinks.support} target="_blank" rel="noreferrer">{localization['help-center']}</a></li>
                                <li><button type="button" onClick={onClickFeedback}>{localization['contact-us']}</button></li>
                            </ul>
                        </Column>
                    </ColumnWrap>
                    <Column>
                        <strong>{localization['social-media']}</strong>
                        <p>{localization['footer-social-media-text']}</p>
                        <SnsButtons>
                            <SnsButton theme="t" href={footerLinks.twitter} target="_blank" rel="noreferrer">Twitter</SnsButton>
                            <SnsButton theme="f" href={footerLinks.facebook} target="_blank" rel="noreferrer">Facebook</SnsButton>
                            <SnsButton theme="i" href={footerLinks.instagram} target="_blank" rel="noreferrer">Instagram</SnsButton>
                            <SnsButton theme="y" href={footerLinks.youtube} target="_blank" rel="noreferrer">Youtube</SnsButton>
                        </SnsButtons>
                    </Column>
                    {/* <LanguageSelector> */}
                    {/*    <LangSelector theme="footer" langsList={langsList} selected={langCode} onClickPrefs={onClickSelectLang} />*/}
                    {/* </LanguageSelector> */}
                </Columns>
                { !isApp && (
                    <Banners>
                        {/* <Banner theme="chrome" href={footerLinks.chrome} target="_blank" rel="noreferrer">Chrome Web Store</Banner> */}
                        <Banner theme="appStore" href={footerLinks.appStore} target="_blank" rel="noreferrer">AppStore</Banner>
                        <Banner theme="playStore" href={footerLinks.playStore} target="_blank" rel="noreferrer">Google Play</Banner>
                    </Banners>
                )}
                <Copyright lang="en">&copy; Mirinae Technologies Inc.</Copyright>
            </FooterInner>
        </Footer>
    </div>
);

export default FooterComponent;