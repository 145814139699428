import React from 'react';

const IconBooksDisable = () => (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M21.7517 32.7263L21.7517 4.65626C21.7517 3.78685 22.4566 3.08195 23.3253 3.08195L27.8263 3.08195C28.695 3.08195 29.3999 3.78685 29.3999 4.65626L29.3999 32.7263C29.3999 33.5957 28.695 34.3006 27.8263 34.3006H23.3253C22.4566 34.3006 21.7517 33.5957 21.7517 32.7263Z" fill="#C4C4C4" />
        <path fillRule="evenodd" clipRule="evenodd" d="M29.3999 32.7266V23.6434H21.7517V32.7266C21.7517 33.596 22.4566 34.3002 23.3253 34.3002H27.8263C28.695 34.3002 29.3999 33.596 29.3999 32.7266Z" fill="#DFDFDF" />
        <path fillRule="evenodd" clipRule="evenodd" d="M24.6509 16.3248V8.14386C24.6509 7.63356 25.0653 7.21986 25.5756 7.21986C26.0866 7.21986 26.501 7.63356 26.501 8.14386V16.3248C26.501 16.8351 26.0866 17.2495 25.5756 17.2495C25.0653 17.2495 24.6509 16.8351 24.6509 16.3248Z" fill="#DFDFDF" />
        <path fillRule="evenodd" clipRule="evenodd" d="M14.1045 30.3443L14.1045 2.2743C14.1045 1.4049 14.8087 0.699997 15.6781 0.699997L20.1784 0.699997C21.0471 0.699997 21.752 1.4049 21.752 2.2743L21.752 30.3443C21.752 31.2137 21.0471 31.9186 20.1784 31.9186H15.6781C14.8087 31.9186 14.1045 31.2137 14.1045 30.3443Z" fill="#F4F4F4" />
        <path fillRule="evenodd" clipRule="evenodd" d="M21.7515 30.3443V21.2611H14.104L14.104 30.3443C14.104 31.2137 14.8082 31.9186 15.6776 31.9186H20.1779C21.0466 31.9186 21.7515 31.2137 21.7515 30.3443Z" fill="#DFDFDF" />
        <path fillRule="evenodd" clipRule="evenodd" d="M17.0036 13.9428V5.7619C17.0036 5.2516 17.4173 4.8372 17.9276 4.8372C18.4386 4.8372 18.853 5.2516 18.853 5.7619V13.9428C18.853 14.4531 18.4386 14.8675 17.9276 14.8675C17.4173 14.8675 17.0036 14.4531 17.0036 13.9428Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M6.45629 32.7263L6.45629 4.65626C6.45629 3.78685 7.16119 3.08195 8.02989 3.08195L12.5302 3.08195C13.3996 3.08195 14.1045 3.78685 14.1045 4.65626L14.1045 32.7263C14.1045 33.5957 13.3996 34.3006 12.5302 34.3006H8.02989C7.16119 34.3006 6.45629 33.5957 6.45629 32.7263Z" fill="#DFDFDF" />
        <path fillRule="evenodd" clipRule="evenodd" d="M14.1045 32.7266L14.1045 23.6434H6.45629L6.45629 32.7266C6.45629 33.596 7.16119 34.3002 8.02989 34.3002H12.5302C13.3996 34.3002 14.1045 33.596 14.1045 32.7266Z" fill="#C4C4C4" />
        <path fillRule="evenodd" clipRule="evenodd" d="M9.35519 16.3248L9.35519 8.14386C9.35519 7.63356 9.76959 7.21986 10.2799 7.21986C10.7909 7.21986 11.2046 7.63356 11.2046 8.14386L11.2046 16.3248C11.2046 16.8351 10.7909 17.2495 10.2799 17.2495C9.76959 17.2495 9.35519 16.8351 9.35519 16.3248Z" fill="#FBFBFB" />
    </svg>
);

export default IconBooksDisable;