import React from 'react';

const ArrowLeft = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || '9'} height={props.height || '15'} viewBox="0 0 9 15">
        <g fill="none" fillRule="evenodd">
            <g fill={props.color ? props.color : '#3C3C3B'}>
                <path d="M8.994 13.429c.004-.412-.157-.81-.45-1.114L3.765 7.5l4.78-4.815c.608-.628.608-1.595 0-2.223C8.262.168 7.862 0 7.443 0c-.419 0-.819.168-1.102.462L.458 6.39c-.61.626-.61 1.594 0 2.22l5.876 5.929c.283.294.683.462 1.103.462.419 0 .819-.168 1.102-.462.292-.302.453-.698.45-1.109" transform="translate(-457 -183) translate(457 183)" />
            </g>
        </g>
    </svg>
);

export default ArrowLeft;