import React from 'react';

const CheckCircle = ({ width, height, color}) => (
    <svg width={width || '16'} height={height || '16'} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill={color || '#FFF'} fillRule="evenodd" clipRule="evenodd" d="M10.664 2.0212C9.37472 1.44673 7.93428 1.30442 6.55751 1.61548C5.18073 1.92654 3.94139 2.67432 3.02433 3.74729C2.10726 4.82025 1.56161 6.16092 1.46873 7.56933C1.37586 8.97775 1.74076 10.3785 2.50899 11.5626C3.27723 12.7466 4.40765 13.6507 5.73165 14.1398C7.05566 14.629 8.50232 14.677 9.85587 14.2768C11.2094 13.8766 12.3973 13.0496 13.2425 11.9191C14.0875 10.7887 14.5446 9.41549 14.5455 8.00416C14.5455 8.00402 14.5455 8.0043 14.5455 8.00416M14.5455 8.00416V7.33507C14.5455 6.93341 14.8711 6.6078 15.2727 6.6078C15.6744 6.6078 16 6.93341 16 7.33507V8.00416C15.999 9.7293 15.4404 11.4083 14.4075 12.79C13.3745 14.1718 11.9226 15.1826 10.2683 15.6717C8.61394 16.1608 6.84581 16.1021 5.22758 15.5042C3.60934 14.9064 2.22772 13.8015 1.28877 12.3542C0.349813 10.907 -0.096167 9.19503 0.0173414 7.47363C0.13085 5.75223 0.797765 4.11364 1.91862 2.80224C3.03948 1.49083 4.55423 0.576885 6.23695 0.196696C7.91967 -0.183493 9.68021 -0.00955142 11.256 0.692579C11.6229 0.856055 11.7878 1.286 11.6243 1.65289C11.4608 2.01978 11.0309 2.18468 10.664 2.0212" />
        <path fill={color || '#FFF'} fillRule="evenodd" clipRule="evenodd" d="M15.7876 1.67156C16.0718 1.95543 16.072 2.41592 15.7881 2.70008L8.5154 9.98007C8.37904 10.1166 8.19402 10.1933 8.00107 10.1933C7.80812 10.1934 7.62306 10.1168 7.48663 9.98033L5.30481 7.79851C5.02079 7.5145 5.02079 7.05401 5.30481 6.76999C5.58883 6.48598 6.04931 6.48598 6.33333 6.76999L8.00063 8.43729L14.7591 1.67207C15.043 1.38791 15.5035 1.38768 15.7876 1.67156Z" />
    </svg>
);

export default CheckCircle;
