export const fontKoKR = 'TmoneyRoundWind';

export const wikinaePalette = {
    primary: {
        black: '#2D4340',
        background: '#F0F9F8',
        white: '#FFF'
    },
    main: {
        main: '#0FA190',
        ligten: '#6FC7BC',
        darken: '#0B796C',
    },
    desat: {
        1: '#576966',
        2: '#8E9E9C',
        3: '#C9D5D3',
        4: '#DAE4E3',
    },
    effect: {
        shadow: 'rgba(45, 67, 64, 0.3);',
        disabled: '#D8D8D8',
    },
    warn: {
        main: '#B33732',
    },
};

export const wikinaeSize = {
    wrap: '1200px',
    inner: '980px',
    header: '100px',
    mobileHeader: '70px',
    rightColumn: '300px',
    columnGap: '20px',
    minCardWidth: 235,
    breakpoint: {
        footerLarge: '925px',
        footerSmall: '750px',
        lg: '1000px',
        md: '800px',
        sm: '680px',
        xs: '520px',
    },
};

