import React from 'react';
import styled from 'styled-components/macro';

import IconClose from '../icons/Close';
import IconCloseBig from '../icons/CloseBig';
import { palette } from '../../defines/styles';

const Button = styled.button`
    ${props => (props.theme === 'small' ? (`
        width: 16px;
        height: 16px;
        svg {
            width: 8px;
            height: 8px;
        }
    `) : (`
        width: 20px;
        height: 20px;
    `))};
    font-size: 0;
    margin: ${props => (props.margin ? props.margin : '0')};
    padding: 0;
    border: 0;
    background: transparent;

    &:hover:not(:disabled) {
        svg g > g:first-child path {
            fill: ${palette.point.black};
            stroke: ${palette.point.black};
        }
        svg g > g:last-child path {
            fill: ${palette.point.red};
            stroke: ${palette.point.red};
        }
    }

    ${props => props.disabled && (`
        svg g > g:first-child path {
            fill: ${palette.gray.light};
            stroke: ${palette.gray.light};
        }
        svg g > g:last-child path {
            fill: ${palette.point.gray};
            stroke: ${palette.point.gray};
        }
    `)}
`;

const CloseButton = props => (
    <Button type="button" {...props}>
        { props.theme === 'large' ? <IconCloseBig /> : <IconClose width={props.width || '11'} height={props.height || '11'} /> }
    </Button>
);

export default CloseButton;