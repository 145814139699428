import React from 'react';

const IconAnalyzeDisable = () => (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
            x="28.7002" y="7.7" width="4.9" height="2.1" rx="1.05"
            fill="#DFDFDF" />
        <rect y="21" width="6.3" height="2.1" rx="1.05" fill="#DFDFDF" />
        <path fillRule="evenodd" clipRule="evenodd" d="M13.4041 23.4626C9.40095 20.3621 9.40237 14.3603 13.4055 11.2605C16.1347 9.14727 20.0439 9.14727 22.7731 11.2612C26.7763 14.361 26.7756 20.3636 22.7717 23.4633C20.0425 25.5766 16.1326 25.5766 13.4041 23.4626ZM32.5156 28.9808L29.408 25.8732C28.2372 24.7024 27.8083 22.9448 28.4108 21.4028C29.9379 17.4907 29.169 12.8844 26.0941 9.68146C21.9985 5.41516 15.087 5.147 10.6877 9.0982C5.92347 13.3752 5.77552 20.7384 10.2438 25.2067C13.4482 28.4111 18.1391 29.2354 22.1145 27.6891C23.6715 27.0845 25.444 27.5248 26.6248 28.7062L29.7445 31.8259C30.6151 32.6966 32.0945 32.5892 32.8158 31.503C33.3393 30.7135 33.1857 29.6501 32.5156 28.9808Z" fill="#C4C4C4" />
        <path fillRule="evenodd" clipRule="evenodd" d="M30.6629 27.1288L27.8555 29.9363L29.7439 31.8262C30.5192 32.6008 31.7768 32.6008 32.5521 31.8262C33.3274 31.0509 33.3274 29.7926 32.5521 29.0173L30.6629 27.1288Z" fill="#DFDFDF" />
        <path fillRule="evenodd" clipRule="evenodd" d="M30.6629 27.1288L27.8555 29.9363L28.7062 30.7884L31.5143 27.9795L30.6629 27.1288Z" fill="#C4C4C4" />
        <rect
            x="1.3999" y="2.10001" width="13.3" height="2.8" rx="1.4"
            fill="#DFDFDF" />
        <path d="M20.7059 12.25C20.1722 12.25 19.7396 12.6826 19.7396 13.2163V21.7837C19.7396 22.3174 20.1722 22.75 20.7059 22.75C21.2396 22.75 21.6722 22.3174 21.6722 21.7837V17.6095H22.3624C22.7698 17.6095 23.1001 17.2792 23.1001 16.8718C23.1001 16.4645 22.7698 16.1342 22.3624 16.1342H21.6722V13.2163C21.6722 12.6826 21.2396 12.25 20.7059 12.25ZM13.5196 19.1883C13.0572 19.4644 12.9109 20.0834 13.2417 20.5084C13.4855 20.8216 13.9164 20.9265 14.266 20.7385C17.1028 19.2121 18.2831 17.1026 18.5632 14.5992C18.6425 13.8906 18.0537 13.3104 17.3407 13.3104H13.9786C13.5776 13.3104 13.2525 13.6355 13.2525 14.0365C13.2525 14.4375 13.5776 14.7626 13.9786 14.7626H16.6745C16.4078 16.7006 15.3829 18.0758 13.5196 19.1883Z" fill="#DFDFDF" />
    </svg>
);

export default IconAnalyzeDisable;