import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.div`
    &:hover {
        & path {
            stroke: #48A9A6;
        }
    }
`;

const IconHamburger = () => (
    <IconWrapper>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 18H21" stroke="#3C3C3B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3 12H21" stroke="#3C3C3B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3 6H21" stroke="#3C3C3B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </IconWrapper>
);

export default IconHamburger;