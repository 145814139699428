import React from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
    position: absolute;
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    gap: 5px;
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: flex-end;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    overflow: hidden;

    span {
        display: inline-block;
        position: relative;
        width: 5px;
        height 1px;
        overflow: hidden;
        background: #00D7D0;
        color: transparent;
        border-radius: 3px;
        animation-name: pulse-${props => props.size};
        animation-duration: 1s;
        animation-iteration-count: infinite;
    }

    @keyframes pulse-${props => props.size} {
        0% {
            height: 1px;
            margin-top: 0;
        }
        10% {
            height: ${props => Math.ceil((props.size - 16) * 0.1)}px;
            margin-top: -${props => Math.ceil((props.size - 16) * 0.1)}px;
        }
        50% {
            height: ${props => Math.ceil((props.size - 16) * 0.5)}px;
            margin-top: -${props => Math.ceil((props.size - 16) * 0.5)}px;
        }
        60% {
            height: ${props => Math.ceil((props.size - 16) * 0.6)}px;
            margin-top: -${props => Math.ceil((props.size - 16) * 0.6)}px;
        }
        80% {
            height: ${props => Math.ceil((props.size - 16) * 0.8)}px;
            margin-top: -${props => Math.ceil((props.size - 16) * 0.8)}px;
        }
        100% {
            height: 1px;
            margin-top: 0;
        }
    }
`;

const Bar1 = styled.span`
    animation-delay: 0.5s;
`;

const Bar2 = styled.span`
    animation-delay: 0.2s;
`;

const Bar3 = styled.span`
    animation-delay: 0.8s;
`;

function AnimatedMusicBar({ size = 55 }) {
    return (
        <Wrapper size={size}>
            <Bar1 />
            <Bar2 />
            <Bar3 />
        </Wrapper>
    )
}

export default AnimatedMusicBar;