import React from 'react';

const Close = props => (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={props.width || '10'} height={props.height || '10'} viewBox="0 0 10 10">
        <defs>
            <path id="3bj6qe185a" d="M0 0L10 0 10 10 0 10z" />
            <path id="2t88x26gic" d="M0 0L10 0 10 10 0 10z" />
        </defs>
        <g fill="none" fillRule="evenodd">
            <g transform="translate(-457 -239) translate(457 239)">
                <mask id="qaimnrbt4b" fill="#fff">
                    <use xlinkHref="#3bj6qe185a" />
                </mask>
                <path fill="#F63A3A" d="M1 10c-.256 0-.512-.098-.707-.293-.391-.391-.391-1.023 0-1.414l8-8c.391-.391 1.023-.391 1.414 0s.391 1.023 0 1.414l-8 8C1.512 9.902 1.256 10 1 10" mask="url(#qaimnrbt4b)" />
            </g>
            <g transform="translate(-457 -239) translate(457 239) matrix(-1 0 0 1 10 0)">
                <mask id="4g9vmbxmjd" fill="#fff">
                    <use xlinkHref="#2t88x26gic" />
                </mask>
                <path fill="#3C3C3B" d="M.293 9.707c-.391-.391-.391-1.023 0-1.414l8-8c.391-.391 1.023-.391 1.414 0s.391 1.023 0 1.414l-8 8C1.512 9.902 1.256 10 1 10c-.256 0-.512-.098-.707-.293z" mask="url(#4g9vmbxmjd)" />
            </g>
        </g>
    </svg>
);

export default Close;