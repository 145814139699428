import React from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
    stroke: #3C3C3B;
    &:hover {
        stroke: #48A9A6;
    }
`;

const IconChevronRight = () => (
    <Wrapper>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 3.33333L10.6667 8L6 12.6667" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    </Wrapper>
);

export default IconChevronRight;
