import React from 'react';

const IconLogo = () => (
    <svg width="143" height="35" viewBox="0 0 143 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask
            id="mask0_2328_4262" maskUnits="userSpaceOnUse" x="79" y="9"
            width="16" height="17">
            <path fillRule="evenodd" clipRule="evenodd" d="M79.579 9.10767H94.518V25.8999H79.579V9.10767Z" fill="white" />
        </mask>
        <g mask="url(#mask0_2328_4262)">
            <path fillRule="evenodd" clipRule="evenodd" d="M92.558 25.9002C91.4756 25.9002 90.5979 25.0225 90.5979 23.9401V16.6401C90.5979 15.6129 90.1812 14.6787 89.4539 14.0769C87.8481 12.7491 86.4282 12.6751 84.8529 13.8409C84.0057 14.4674 83.499 15.4917 83.499 16.5806V23.9401C83.499 25.0225 82.6214 25.9002 81.539 25.9002C80.4566 25.9002 79.579 25.0225 79.579 23.9401V16.5806C79.579 14.254 80.6795 12.0515 82.5219 10.6889C85.5367 8.45959 88.9726 8.59244 91.9518 11.0563C93.583 12.405 94.518 14.4406 94.518 16.6401V23.9401C94.518 25.0225 93.6403 25.9002 92.558 25.9002Z" fill="#3C3C3B" />
        </g>
        <mask
            id="mask1_2328_4262" maskUnits="userSpaceOnUse" x="11" y="9"
            width="27" height="17">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.8567 9.10767H37.8139V25.9001H11.8567V9.10767Z" fill="white" />
        </mask>
        <g mask="url(#mask1_2328_4262)">
            <path fillRule="evenodd" clipRule="evenodd" d="M25.8171 10.689C25.4505 10.9605 25.1224 11.2726 24.8175 11.6051C24.6331 11.412 24.4371 11.2283 24.2295 11.0563C21.2517 8.5932 17.8144 8.4589 14.7996 10.689C12.9565 12.0515 11.8567 14.254 11.8567 16.5806V23.9402C11.8567 25.0225 12.7343 25.9002 13.8167 25.9002C14.8991 25.9002 15.7767 25.0225 15.7767 23.9402V16.5806C15.7767 15.4917 16.2827 14.4675 17.1299 13.841C18.7059 12.6751 20.1243 12.7492 21.7308 14.0769C22.4582 14.6787 22.8749 15.6122 22.8749 16.6402V23.9402C22.8749 25.0225 23.7526 25.9002 24.8349 25.9002C25.9173 25.9002 26.795 25.0225 26.795 23.9402V16.6402V16.5806C26.795 15.4917 27.3009 14.4675 28.1488 13.841C29.7241 12.6751 31.1426 12.7492 32.7498 14.0769C33.4765 14.6787 33.8931 15.6122 33.8931 16.6402V23.9402C33.8931 25.0225 34.7708 25.9002 35.8532 25.9002C36.9363 25.9002 37.8139 25.0225 37.8139 23.9402V16.6402C37.8139 14.4406 36.8782 12.4051 35.2477 11.0563C32.2699 8.5932 28.8334 8.4589 25.8171 10.689Z" fill="#3C3C3B" />
        </g>
        <mask
            id="mask2_2328_4262" maskUnits="userSpaceOnUse" x="52" y="9"
            width="13" height="17">
            <path fillRule="evenodd" clipRule="evenodd" d="M52.8138 9.10254H64.5579V25.9H52.8138V9.10254Z" fill="white" />
        </mask>
        <g mask="url(#mask2_2328_4262)">
            <path fillRule="evenodd" clipRule="evenodd" d="M54.7739 25.9002C53.6915 25.9002 52.8138 25.0226 52.8138 23.9402V16.5807C52.8138 14.2541 53.9136 12.0516 55.7568 10.689C58.2061 8.87851 60.9559 8.60846 63.4996 9.92603C64.4607 10.4248 64.8353 11.608 64.338 12.5684C63.8393 13.5296 62.6561 13.9042 61.6956 13.4069C60.9327 13.012 59.7298 12.6265 58.087 13.841C57.2399 14.4675 56.7339 15.4918 56.7339 16.5807V23.9402C56.7339 25.0226 55.8562 25.9002 54.7739 25.9002Z" fill="#3C3C3B" />
        </g>
        <path fillRule="evenodd" clipRule="evenodd" d="M44.1501 10.3707L50.0229 22.9642C50.51 24.0088 50.1056 25.2284 49.1191 25.6886C48.1326 26.1481 46.9384 25.6741 46.4513 24.6302L40.5785 12.036C40.0914 10.9906 40.4957 9.77177 41.4823 9.31153C42.4688 8.85129 43.663 9.32605 44.1501 10.3707Z" fill="#87CBC8" />
        <path fillRule="evenodd" clipRule="evenodd" d="M76.7813 22.9642C77.2684 24.0088 76.8633 25.2284 75.8775 25.6886C74.891 26.1481 73.6968 25.6741 73.209 24.6302L67.3369 12.036C66.8498 10.9906 67.2542 9.77177 68.2407 9.31153C69.2272 8.85129 70.4214 9.32605 70.9085 10.3707L76.7813 22.9642Z" fill="#87CBC8" />
        <path fillRule="evenodd" clipRule="evenodd" d="M104.547 21.9798C102.078 21.9798 100.067 19.9697 100.067 17.5001C100.067 15.0297 102.078 13.0203 104.547 13.0203C107.017 13.0203 109.027 15.0297 109.027 17.5001C109.027 19.9697 107.017 21.9798 104.547 21.9798ZM104.547 9.10059C99.9157 9.10059 96.1473 12.8682 96.1473 17.5004C96.1473 22.1318 99.9157 25.9001 104.547 25.9001C106.237 25.9001 107.809 25.3942 109.128 24.5325C109.38 25.323 110.112 25.9001 110.987 25.9001C112.069 25.9001 112.947 25.0225 112.947 23.9401V18.3199C112.947 18.2256 112.932 18.1356 112.919 18.0455C112.932 17.8641 112.947 17.684 112.947 17.5004C112.947 12.8682 109.179 9.10059 104.547 9.10059Z" fill="#3C3C3B" />
        <mask
            id="mask3_2328_4262" maskUnits="userSpaceOnUse" x="114" y="9"
            width="18" height="17">
            <path fillRule="evenodd" clipRule="evenodd" d="M114.402 9.09985H131.202V25.8999H114.402V9.09985Z" fill="white" />
        </mask>
        <g mask="url(#mask3_2328_4262)">
            <path fillRule="evenodd" clipRule="evenodd" d="M118.773 15.555C119.499 14.0574 121.03 13.0208 122.803 13.0208C124.569 13.0208 126.096 14.0509 126.824 15.5397H118.925C118.873 15.5397 118.824 15.5514 118.773 15.555ZM122.803 9.09985C118.171 9.09985 114.402 12.8682 114.402 17.5004C114.402 22.1318 118.171 25.9001 122.803 25.9001C123.885 25.9001 124.763 25.0225 124.763 23.9401C124.763 22.8578 123.885 21.9801 122.803 21.9801C121.03 21.9801 119.499 20.942 118.773 19.4444C118.824 19.4488 118.873 19.4604 118.925 19.4604H129.242C130.325 19.4604 131.202 18.5827 131.202 17.5004C131.202 12.8682 127.434 9.09985 122.803 9.09985Z" fill="#3C3C3B" />
        </g>
    </svg>
);

export default IconLogo;