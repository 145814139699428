import React from 'react';

const IconHelp = () => (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.75 15C3.75 21.2132 8.7868 26.25 15 26.25C21.2132 26.25 26.25 21.2132 26.25 15C26.25 8.7868 21.2132 3.75 15 3.75C8.7868 3.75 3.75 8.7868 3.75 15Z" fill="#3C3C3B"/>
        <path d="M15 25C9.47715 25 5 20.5228 5 15H2.5C2.5 21.9036 8.09644 27.5 15 27.5V25ZM5 15C5 9.47715 9.47715 5 15 5V2.5C8.09644 2.5 2.5 8.09644 2.5 15H5ZM15 5C20.5228 5 25 9.47715 25 15H27.5C27.5 8.09644 21.9036 2.5 15 2.5V5ZM25 15C25 20.5228 20.5228 25 15 25V27.5C21.9036 27.5 27.5 21.9036 27.5 15H25Z" fill="#3C3C3B" />
        <path d="M14.9284 16.2498C14.9284 14.6873 16.2794 14.4258 16.7445 14.0028C17.2112 13.5785 17.5 12.9918 17.5 12.3435C17.5 11.0491 16.3487 9.99976 14.9284 9.99976C14.1605 9.99976 13.4711 10.3066 12.9999 10.7931C12.782 11.018 12.6108 11.2813 12.5 11.5705" stroke="white" strokeWidth="1.875" strokeLinecap="round" />
        <path d="M15.062 20.0002H16.312C16.312 20.6906 15.7524 21.2502 15.062 21.2502V20.0002ZM15.062 19.8752L15.0617 18.6252C15.3933 18.6252 15.7113 18.7568 15.9458 18.9913C16.1803 19.2257 16.312 19.5437 16.312 19.8752H15.062ZM14.9375 19.8753H13.6875C13.6875 19.185 14.247 18.6254 14.9372 18.6253L14.9375 19.8753ZM14.9375 20.0002V21.2502C14.2471 21.2502 13.6875 20.6906 13.6875 20.0002H14.9375ZM13.812 20.0002V19.8752H16.312V20.0002H13.812ZM15.0623 21.1252L14.9378 21.1253L14.9372 18.6253L15.0617 18.6252L15.0623 21.1252ZM16.1875 19.8753V20.0002H13.6875V19.8753H16.1875ZM14.9375 18.7502H15.062V21.2502H14.9375V18.7502Z" fill="white" />
    </svg>
);

export default IconHelp;