import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { os } from '@mirinae/js-utils/utils/userAgent';
import { palette } from '@mirinae/react-ui';

import { Button, Popup } from '../gnbStyles';
import ToggleButton from './ToggleButton';
import LangSelector from './LangSelector';
import IconArrowLeft from '../assets/IconArrowLeft';
import IconCheckGreen from '../assets/IconCheckGreen';
import { ImgConfigMode, ImgCheck } from '../assets/ImageBase64';

const _ = require('lodash');

const ConfigLayerWrapper = styled(Popup)`
    ${os.isOthers ? `
        min-height: 200px;
        &.hide {
            position: absolute;
            top: -999px;
        }
    ` : `
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        width: ${`${window.innerWidth}px`};
        border-radius: 0;
        transition: transform 0.4s cubic-bezier(0.32, 0.08, 0.24, 1);
        &.hide {
            transform: translateX(100%);
        }
    `}
`;
const ConfigLayerHead = styled.div`
    color: ${palette.primary.black};
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    ${os.isOthers ? `
        padding: 20px 20px 10px;
        border-bottom: 1px solid ${palette.gray[7]};
    ` : `
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 68px;
        padding-left: 68px;
        padding-right: 68px;
        text-align: center;
    `}
`;
const ConfigLayerBody = styled.div`
    padding: 15px 20px 20px;
    font-size: 14px;
`;
const ConfigLayerContent = styled.div`
    position: relative;
    font-size: 14px;
    & + & {
        margin-top: 15px;
        padding-top: 15px;
        border-top: 1px solid ${palette.gray[7]};
    }
`;
const ConfigTitle = styled.div`
    margin-bottom: 15px;
    color: ${palette.primary.black};
    font-weight: 600;
    line-height: 24px;
    ${!os.isOthers && `
        font-size: 16px;
        font-weight: 500;
        line-height: 36px;
    `}
`;
const ConfigItem = styled.div`
    display: flex;
    align-items: center;
    height: 28px;
    & + & {
        margin-top: 15px;
    }
    strong {
        flex-shrink: 0;
        display: block;
        width: 140px;
        margin-right: 5px;
        color: ${palette.gray[1]};
        font-weight: 500;
    }
    & > div {
        display: flex;
        align-items: center;
        width: max-content;
    }
    ${os.isOthers && `
        strong {
            text-transform: capitalize;
        }
    `}
`;
const ConfigText = styled.div`
    margin-top: -10px;
    line-height: 22px;
    font-weight: 400;
    font-size: 12px;
    color: ${palette.gray[2]};
    span {
        display: block;
        color: ${palette.gray[4]};
    }
`;
const ConfigButton = styled(Button)`
    height: 28px;
    border-radius: 14px;
    padding: 0 15px;
    background-color: ${palette.primary.white};
    color: ${palette.gray[3]};
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    text-transform: capitalize;
    :hover {
        background-color: ${palette.main.darken[1]};
        color: ${palette.primary.white};
        font-weight: 500;
    }
    ${props => props.selected && `
        background-color: ${palette.main.darken[1]};
        color: ${palette.primary.white};
        font-weight: 500;
    `}
    & + & {
        margin-left: 5px;
    }
`;
const LangSelectWrapper = styled.div`
    padding-left: 10px;
`;
const ConfigMobileBody = styled.div`
    padding: 15px 20px;
    max-height: calc(100vh - 68px);
    overflow-y: auto;
`;
const ConfigRowBotton = styled.div`
    position: relative;
    padding-top: 20px;
    border-top: 1px solid ${palette.gray[7]};
`;
const ConfigRowButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: space-between;;
    width: 100%;
    font-size: 16px;
    em {
        font-style: normal;
        color: ${palette.main.darken[1]};
    }
    ${props => (props.theme === 'sub' ? `
        height: 46px;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid ${palette.gray[7]};
        &.active {
            color: ${palette.main.darken[1]};
        }
    ` : `
        height: 36px;
        margin-bottom: 20px;
    `)}
`;
const ConfigRadioSelect = styled.div`
    & + & {
        margin-top: 30px;
        padding-top: 30px;
        border-top: 1px solid ${palette.gray[7]};
    }
`;
const ConfigRadioButton = styled(Button)`
    display: flex;
    align-items: center;
    width: 100%;
    height: 36px;
    line-height: 36px;
    padding-left: 34px;
    background: url(${ImgCheck}) no-repeat 0 ${props => (props.theme === 'checked' ? '0' : '100%')};
    color: ${palette.gray[1]};
    font-size: 16px;
    svg {
        margin-left: 10px;
    }
`;
const LearningModeImg = styled.div`
    width: 336px;
    height: ${props => (props.theme === 'easy' ? '143px' : '170px')};
    margin: 20px auto;
    background: url(${ImgConfigMode}) no-repeat ${props => (props.theme === 'easy' ? '50% 0' : '50% 100%')}/100% auto;

    @media only screen and (max-width: 376px) {
        transform: scale(0.7);
        transform-origin: left;
    }
`;
const BackButton = styled(Button)`
    position: absolute;
    left: 0;
    top: 0;
    width: 68px;
    height: 68px;
`;
const ConfigDescText = styled.div`
    color: ${palette.gray[4]};
    font-size: 12px;
`;

const ConfigSubLayer = ({ visible, selected, dataset, prefs, localization, onClick, onClose }) => (
    <ConfigLayerWrapper theme="sub" className={visible}>
        <ConfigLayerHead>
            {dataset[selected] && dataset[selected].title}
            <BackButton type="button" onClick={onClose}><IconArrowLeft /></BackButton>
        </ConfigLayerHead>
        <ConfigMobileBody>
            { dataset[selected] && (selected === 'mode' ? Object.entries(dataset[selected].data).map((d, i) => (
                <ConfigRadioSelect key={i}>
                    <ConfigRadioButton
                        type="button"
                        theme={d[0] === prefs.analysis[selected] ? 'checked' : null}
                        onClick={() => onClick({ [selected]: d[0] })}
                    >{d[1]}</ConfigRadioButton>
                    <LearningModeImg theme={d[0]} />
                </ConfigRadioSelect>
            )) : Object.entries(dataset[selected].data).map((d, i) => (
                <ConfigRowButton
                    key={i} type="button" theme="sub"
                    className={d[0] === (selected === 'language' ? prefs.language : prefs.analysis[selected]) ? 'active' : null}
                    onClick={() => onClick({ [selected]: d[0] })}
                >{d[1]} {d[0] === (selected === 'language' ? prefs.language : prefs.analysis[selected]) && <IconCheckGreen />}
                </ConfigRowButton>
            )))}
            {selected === 'language' && <ConfigDescText>{localization['config-language-text']}</ConfigDescText>}
        </ConfigMobileBody>
    </ConfigLayerWrapper>
);

const ConfigLayer = ({
    theme = 'hide',
    langsList,
    prefs: incomingPrefs,
    localization,
    onClickPrefs, onClose,
    onMouseOver, onMouseOut,
}) => {
    const [prefs, setPrefs] = useState(incomingPrefs);
    const [mobileSelected, setMobileSelected] = useState('');
    // console.log('ConfigLayer incomingPrefs', incomingPrefs.language, incomingPrefs);

    const handleSetPref = (prefSet, pref, value) => {
        const prefUpdate = prefSet ? { [prefSet]: { [pref]: value } } : { [pref]: value };
        setPrefs(prev => _.merge(prev, prefUpdate));
        onClickPrefs(prefUpdate);
        setMobileSelected('');
    };
    // const handleClickToggle = (prefSet, pref, value = false) => {
    //     const prefUpdate = prefSet ? { [prefSet]: { [pref]: value } } : { [pref]: value };
    //     setPrefs(prev => ({ ...prev, romanization: value }));
    //     onClickPrefs({ romanization: value });
    // };
    const handleClickMobileButton = (value) => {
        onClickPrefs(value);
        setMobileSelected('');
    };

    const configDataset = {
        language: { title: localization.language, data: langsList },
        mode: { prefSet: 'analysis', title: localization['learning-mode'], data: { normal: localization.normal, easy: localization.easy } },
        fontSize: { prefSet: 'analysis', title: localization['font-size'], data: { small: localization.small, normal: localization.normal, large: localization.large } },
        speed: { prefSet: 'analysis', title: os.isOthers ? localization.speed : localization['phonetic-speed'], data: { slow: localization.slow, normal: localization.normal, fast: localization.fast } },
        gender: { prefSet: 'analysis', title: os.isOthers ? localization.gender : localization['phonetic-gender'], data: { male: localization.male, female: localization.female } },
    };

    useEffect(() => {
        setPrefs(incomingPrefs);
        console.log('ConfigLayer useEffect incomingPrefs', incomingPrefs.language, incomingPrefs);
    }, [incomingPrefs]);

    const prefSet = (d) => d[1].prefSet ? prefs[d[1].prefSet] : prefs;

    return (
        <>
            <ConfigLayerWrapper onMouseOver={onMouseOver} onMouseOut={onMouseOut} className={theme}>
                <ConfigLayerHead>
                    {localization['analyze-setting']}
                    {!os.isOthers && (
                        <BackButton type="button" onClick={onClose}><IconArrowLeft /></BackButton>
                    )}
                </ConfigLayerHead>
                {os.isOthers ? (
                    <ConfigLayerBody>
                        <ConfigLayerContent>
                            <ConfigTitle>{localization.display}</ConfigTitle>
                            <ConfigItem>
                                <strong>{localization.language}</strong>
                                <LangSelectWrapper>
                                    <LangSelector langsList={langsList} selected={prefs.language} onClickPrefs={onClickPrefs} />
                                </LangSelectWrapper>
                            </ConfigItem>
                            {Object.entries(configDataset).map((d, i) => (i === 1 || i === 2) && (
                                <ConfigItem key={i}>
                                    <strong>{d[1].title}</strong>
                                    <div>
                                        {d[1].data && Object.entries(d[1].data).map((items, j) => (
                                            <ConfigButton
                                                type="button" key={j}
                                                selected={prefSet(d)[d[0]] === items[0]}
                                                onClick={() => handleSetPref(d[1].prefSet, d[0], items[0])}
                                            >{items[1]}
                                            </ConfigButton>
                                        ))}
                                    </div>
                                </ConfigItem>
                            ))}
                        </ConfigLayerContent>
                        <ConfigLayerContent>
                            <ConfigTitle>{localization.phonetic}</ConfigTitle>
                            {Object.entries(configDataset).map((d, i) => (i === 3 || i === 4) && (
                                <ConfigItem key={i}>
                                    <strong>{d[1].title}</strong>
                                    <div>
                                        {d[1].data && Object.entries(d[1].data).map((items, j) => (
                                            <ConfigButton
                                                type="button" key={j}
                                                selected={prefSet(d)[d[0]] === items[0]}
                                                onClick={() => handleSetPref(d[1].prefSet, d[0], items[0])}
                                            >{items[1]}
                                            </ConfigButton>
                                        ))}
                                    </div>
                                </ConfigItem>
                            ))}
                        </ConfigLayerContent>
                        <ConfigLayerContent>
                            <ConfigTitle lang="en">{localization.romanization}</ConfigTitle>
                            <ToggleButton state={prefs.analysis.romanization} onClick={() => handleSetPref('analysis', 'romanization', !prefs.analysis.romanization)} style={{ position: 'absolute', top: '17px', right: 0 }} />
                            <ConfigText>
                                {localization['config-romanization-text']}
                                <span>e.g. 안녕하세요 &gt; annyounghaseyo</span>
                            </ConfigText>
                        </ConfigLayerContent>
                    </ConfigLayerBody>
                ) : (
                    <ConfigMobileBody>
                        {Object.entries(configDataset).map((d, i) => (
                            <ConfigRowButton key={i} type="button" onClick={() => setMobileSelected(d[0])}>
                                {d[1].title}
                                <em>{d[1].data[prefSet(d)[d[0]]]}</em>
                            </ConfigRowButton>
                        ))}
                        <ConfigRowBotton>
                            <ConfigTitle>{localization['romanization-setting']}</ConfigTitle>
                            <ToggleButton state={prefs.analysis.romanization} onClick={() => handleSetPref('analysis', 'romanization', !prefs.analysis.romanization)} style={{ position: 'absolute', top: '28px', right: 0 }} />
                            <ConfigText>
                                {localization['config-romanization-text']}
                                <span>e.g. 안녕하세요 &gt; annyounghaseyo</span>
                            </ConfigText>
                        </ConfigRowBotton>
                    </ConfigMobileBody>
                )}
            </ConfigLayerWrapper>
            {!os.isOthers && (
                <ConfigSubLayer
                    visible={mobileSelected === '' ? 'hide' : 'show'}
                    selected={mobileSelected}
                    prefs={prefs} localization={localization}
                    dataset={configDataset}
                    onClick={handleClickMobileButton}
                    onClose={() => setMobileSelected('')}
                />
            )}
        </>
    );
};

export default ConfigLayer;