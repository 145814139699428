import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { palette } from '@mirinae/react-ui';

import { Button } from '../gnbStyles';

const ButtonWrapper = styled(Button)`
    width: 33px;
    height: 20px;
    padding: 3px;
    border-radius: 10px;
    background-color: ${props => (props.active ? palette.main.darken[1] : palette.gray[3])};
    :before {
        content: '';
        display: block;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        margin-left: ${props => (props.active ? '13px' : '0')};
        transition: all 0.2s ease-out;
        background-color: ${palette.primary.white};
    }
`;

const ToggleButton = ({ style, state, onClick }) => {
    const [active, setActive] = useState(state || false);
    const handleClick = () => {
        setActive(prev => !prev);
        onClick(!active);
    };
    useEffect(() => {
        setActive(state);
    }, [state]);

    return (
        <ButtonWrapper type="button" active={active} onClick={handleClick} style={style} />
    );
};

export default ToggleButton;