import React from 'react';

const IconNavHistoryActive = props => (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19.0785 10.4104C18.7242 10.4199 18.3879 10.5675 18.1425 10.8212C17.897 11.075 17.7622 11.4145 17.7671 11.7662V18.0752C17.7751 18.4248 17.9137 18.759 18.1561 19.0129C18.3984 19.2668 18.7272 19.4222 19.0785 19.449H23.7596C24.1267 19.449 24.4788 19.3043 24.7384 19.0466C24.998 18.789 25.1438 18.4395 25.1438 18.0752C25.1438 17.7108 24.998 17.3613 24.7384 17.1037C24.4788 16.846 24.1267 16.7013 23.7596 16.7013H20.3899V11.7662C20.3948 11.5914 20.3646 11.4174 20.3011 11.2543C20.2375 11.0912 20.142 10.9422 20.02 10.8161C19.898 10.6899 19.7519 10.5891 19.5903 10.5195C19.4287 10.4498 19.2547 10.4128 19.0785 10.4104Z"
            fill="#48A9A6"/>
        <path
            d="M29.5277 6.69924C27.6186 4.77962 25.194 3.46182 22.5509 2.90742C19.9079 2.35302 17.1614 2.58611 14.6482 3.57813C12.135 4.57015 9.96434 6.27796 8.40238 8.49217C6.84042 10.7064 5.95504 13.3307 5.85482 16.0434V16.5528L4.56889 15.4166C4.32718 15.2115 4.03944 15.0686 3.73053 15.0004C3.42162 14.9321 3.10083 14.9405 2.79586 15.0248C2.49217 15.063 2.20521 15.186 1.96757 15.3799C1.72993 15.5738 1.55113 15.8309 1.45148 16.1218C1.37807 16.3828 1.38331 16.6599 1.46655 16.9179C1.54979 17.176 1.70727 17.4034 1.91909 17.5714L6.75108 21.7244C7.10909 22.0323 7.56593 22.1993 8.03701 22.1946C8.50809 22.1993 8.96493 22.0323 9.32294 21.7244L14.0965 17.4539C14.3065 17.2804 14.461 17.0485 14.5407 16.7872C14.6204 16.5259 14.6217 16.2467 14.5446 15.9847C14.4413 15.7085 14.2686 15.4641 14.0431 15.2752C13.8177 15.0864 13.5474 14.9596 13.2587 14.9073C12.9513 14.8395 12.6328 14.8412 12.3261 14.9123C12.0195 14.9835 11.7324 15.1223 11.4856 15.3186L10.2387 16.4548V15.8475C10.3826 14.0032 11.0578 12.2409 12.1818 10.7762C13.3058 9.31151 14.8299 8.20778 16.5678 7.59997C18.3057 6.99217 20.1821 6.90661 21.9676 7.35377C23.753 7.80093 25.3703 8.76142 26.6215 10.1178C27.8728 11.4743 28.7039 13.1678 29.0132 14.9915C29.3225 16.8152 29.0967 18.69 28.3633 20.3867C27.6299 22.0834 26.4207 23.5285 24.8835 24.5454C23.3464 25.5622 21.5478 26.1068 19.7078 26.1125C19.1621 26.1642 18.6553 26.4188 18.2864 26.8264C17.9175 27.2339 17.713 27.7652 17.713 28.3163C17.713 28.8674 17.9175 29.3986 18.2864 29.8062C18.6553 30.2138 19.1621 30.4683 19.7078 30.5201C23.3906 30.5149 26.9211 29.0417 29.5252 26.4234C32.1294 23.8052 33.5946 20.2555 33.5998 16.5528C33.6104 14.7201 33.2555 12.9039 32.5561 11.2114C31.8567 9.51901 30.8269 7.98472 29.5277 6.69924Z"
            fill="#87CBC8"/>
    </svg>
);

export default IconNavHistoryActive;