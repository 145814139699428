import React from 'react';

const IconBooks = () => (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M5.60029 32.4321L5.60029 5.36789C5.60029 4.52964 6.30933 3.85 7.1847 3.85L11.7159 3.85C12.5906 3.85 13.3003 4.52964 13.3003 5.36789L13.3003 32.4321C13.3003 33.2704 12.5906 33.95 11.7159 33.95H7.1847C6.30933 33.95 5.60029 33.2704 5.60029 32.4321" fill="#D1D9D9" />
        <path fillRule="evenodd" clipRule="evenodd" d="M13.3 29.6321L13.3 2.56789C13.3 1.72964 14.009 1.05 14.8844 1.05L19.4156 1.05C20.2903 1.05 21 1.72964 21 2.56789L21 29.6321C21 30.4704 20.2903 31.15 19.4156 31.15H14.8844C14.009 31.15 13.3 30.4704 13.3 29.6321" fill="#DFDFDF" />
        <path fillRule="evenodd" clipRule="evenodd" d="M21.0002 32.4321L21.0002 5.36789C21.0002 4.52964 21.7092 3.85 22.5846 3.85L27.1158 3.85C27.9905 3.85 28.7002 4.52964 28.7002 5.36789L28.7002 32.4321C28.7002 33.2704 27.9905 33.95 27.1158 33.95H22.5846C21.7092 33.95 21.0002 33.2704 21.0002 32.4321" fill="#AFC0C0" />
        <path fillRule="evenodd" clipRule="evenodd" d="M13.3003 32.5024L13.3003 24.15H5.60029L5.60029 32.5024C5.60029 33.3018 6.30933 33.95 7.1847 33.95H11.7159C12.5906 33.95 13.3003 33.3018 13.3003 32.5024" fill="#AFC0C0" />
        <path fillRule="evenodd" clipRule="evenodd" d="M28.7002 32.5024V24.15H21.0002V32.5024C21.0002 33.3018 21.7092 33.95 22.5846 33.95H27.1158C27.9905 33.95 28.7002 33.3018 28.7002 32.5024" fill="#D1D9D9" />
        <path fillRule="evenodd" clipRule="evenodd" d="M8.6506 17.0928L8.6506 8.9119C8.6506 8.4016 9.0643 7.9872 9.5746 7.9872C10.0856 7.9872 10.5 8.4016 10.5 8.9119L10.5 17.0928C10.5 17.6031 10.0856 18.0175 9.5746 18.0175C9.0643 18.0175 8.6506 17.6031 8.6506 17.0928" fill="#F4F4F4" />
        <path fillRule="evenodd" clipRule="evenodd" d="M16.3508 14.2928V6.11189C16.3508 5.60159 16.7645 5.18719 17.2748 5.18719C17.7858 5.18719 18.2002 5.60159 18.2002 6.11189V14.2928C18.2002 14.8031 17.7858 15.2175 17.2748 15.2175C16.7645 15.2175 16.3508 14.8031 16.3508 14.2928" fill="#F4F4F4" />
        <path fillRule="evenodd" clipRule="evenodd" d="M24.0505 17.0928V8.9119C24.0505 8.4016 24.4642 7.9872 24.9745 7.9872C25.4855 7.9872 25.8999 8.4016 25.8999 8.9119V17.0928C25.8999 17.6031 25.4855 18.0175 24.9745 18.0175C24.4642 18.0175 24.0505 17.6031 24.0505 17.0928" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M13.3003 24.15H21.0003V29.7024C21.0003 30.5018 20.2906 31.15 19.4159 31.15H14.8847C14.0093 31.15 13.3003 30.5018 13.3003 29.7024L13.3003 24.15Z" fill="#C4C4C4" />
        <rect x="13.3003" y="21.35" width="7.7" height="2.8" fill="#C4C4C4" />
        <rect x="21" y="24.15" width="7.7" height="2.8" fill="#AFC0C0" />
    </svg>
);

export default IconBooks;