import React from 'react';

const IconNeologisms = () => (
    <svg width="35" height="40" viewBox="0 0 35 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_2282_1576)">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M25.5741 31.5H6.44136C5.44503 31.5 4.6377 30.6927 4.6377 29.6963V5.30367C4.6377 4.30733 5.44503 3.5 6.44136 3.5H25.5741C28.0259 3.5 30.0127 5.48742 30.0127 7.93858V27.0614C30.0127 29.5126 28.0259 31.5 25.5741 31.5Z"
                          fill="#DFDFDF"/>
                    <path
                        d="M17.2037 19.9201C16.8948 20.1321 16.5612 20.3058 16.2104 20.4374C15.1385 20.8052 13.9533 20.6061 13.0604 19.9082C12.8004 19.707 12.4508 19.6649 12.1505 19.7987C11.8501 19.9324 11.6475 20.2204 11.6231 20.5482C11.5986 20.8761 11.7563 21.1909 12.0335 21.3677C12.9404 22.0257 14.0281 22.3881 15.1485 22.4058C16.2545 22.3905 17.3293 22.0371 18.2285 21.3929C18.4434 21.2686 18.5969 21.0605 18.6524 20.8185C18.7079 20.5765 18.6603 20.3223 18.5211 20.1168C18.3767 19.9104 18.1538 19.7724 17.9047 19.7352C17.6555 19.6981 17.4021 19.7649 17.2037 19.9201Z"
                        fill="#AFC0C0"/>
                    <path
                        d="M12.2862 15.3161C12.3058 14.6959 12.3072 14.075 12.2862 13.4548C12.2803 13.2194 12.1794 12.9964 12.0066 12.8365C11.8338 12.6766 11.6036 12.5933 11.3685 12.6057C10.8897 12.6095 10.5039 12.9991 10.5047 13.4779C10.4942 13.7747 10.5047 14.0722 10.5047 14.3697C10.5047 14.6672 10.497 14.9815 10.5047 15.2874C10.5022 15.5218 10.5931 15.7476 10.7572 15.915C10.9213 16.0824 11.1452 16.1777 11.3797 16.1799C11.6145 16.1857 11.842 16.0979 12.012 15.9359C12.182 15.7739 12.2807 15.5509 12.2862 15.3161Z"
                        fill="#AFC0C0"/>
                    <path
                        d="M19.7936 13.501C19.7966 13.1822 19.6293 12.8859 19.3546 12.7239C19.08 12.5619 18.7398 12.5587 18.4621 12.7155C18.1845 12.8723 18.0116 13.1654 18.0086 13.4842C17.9932 14.0872 17.9932 14.6901 18.0086 15.293C18.0244 15.7761 18.4219 16.1589 18.9052 16.1564C19.3885 16.1539 19.7821 15.7672 19.7929 15.2839C19.802 14.9871 19.7929 14.6896 19.7929 14.3928C19.7929 14.096 19.802 13.7957 19.7936 13.501Z"
                        fill="#AFC0C0"/>
                    <path
                        d="M13.6857 18.8484C13.8971 18.8631 14.1092 18.8631 14.3206 18.8484C14.3472 18.8484 14.371 18.8568 14.3983 18.854C15.2786 18.7054 15.9506 17.9861 16.0391 17.0977C16.0489 15.9007 16.0454 14.7037 16.0391 13.5067C16.0433 13.1828 15.8715 12.882 15.5904 12.7211C15.3092 12.5601 14.9629 12.5644 14.6857 12.7321C14.4085 12.8998 14.2441 13.2047 14.2562 13.5284C14.2562 14.6981 14.2562 15.8685 14.2562 17.0585H14.2401C14.0343 17.0529 13.9601 17.0536 13.7039 17.0585C13.3842 17.0553 13.087 17.2228 12.9243 17.4981C12.7616 17.7734 12.7581 18.1145 12.9152 18.3931C13.0723 18.6716 13.366 18.8452 13.6857 18.8484Z"
                        fill="#AFC0C0"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M25.5741 3.5H24.7627V31.5H25.5741C28.0259 31.5 30.0127 29.5126 30.0127 27.0614V7.93858C30.0127 5.48742 28.0259 3.5 25.5741 3.5Z"
                          fill="#AFC0C0"/>
            </g>
            <defs>
                    <filter id="filter0_d_2282_1576" x="-4" y="0" width="43" height="43" filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix"
                                           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2282_1576"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2282_1576"
                                     result="shape"/>
                    </filter>
            </defs>
    </svg>
);

export default IconNeologisms;