import React from 'react';

const IconRocket = () => (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.45575 19.8762C6.67312 19.3574 9.01407 13.8516 12.4264 10.0187L10.2175 9.56757C9.1131 9.35037 7.97537 9.70211 7.18916 10.4888L2.15407 15.5269C1.31767 16.3638 1.58583 17.7861 2.67361 18.2543L6.45575 19.8762Z" fill="#AFC0C0" />
        <path d="M9.77268 29.2174C9.93329 28.6544 9.95241 28.0605 9.82835 27.4883C9.54553 26.1058 8.38963 24.9862 6.99881 24.7475C6.42299 24.6417 5.82995 24.6797 5.27235 24.8582C4.71475 25.0367 4.20983 25.35 3.80244 25.7705C2.89252 26.6978 2.27393 29.541 1.94762 31.3837C1.80657 32.2039 2.54105 32.9154 3.35637 32.7483C5.18775 32.3634 8.00958 31.6428 8.90764 30.7157C9.31492 30.2951 9.61206 29.7804 9.77268 29.2174Z" fill="#DFDFDF" />
        <path fillRule="evenodd" clipRule="evenodd" d="M29.453 2.23327C26.5002 1.78321 19.9757 2.04293 12.2335 10.0358C9.47248 12.8861 7.38111 17.2067 6.49211 19.1615L6.49198 19.1617C6.0495 20.1356 6.30061 21.2596 7.03482 21.9708L12.4739 27.2394C13.2295 27.9713 14.3437 28.1624 15.2964 27.6937C17.2317 26.7548 21.4781 24.5119 24.2374 21.6634C31.9796 13.6705 32.0315 7.14107 31.4876 4.20409L31.4877 4.20408L31.4859 4.19512C31.3888 3.70093 31.1428 3.24827 30.7811 2.89786C30.4193 2.54745 29.9591 2.31605 29.4621 2.2347L29.4621 2.23465L29.453 2.23327Z" fill="#DFDFDF" />
        <path d="M19.8865 10.1925C17.678 10.2277 15.8999 12.0634 15.935 14.2718C15.9702 16.4803 17.8059 18.2585 20.0144 18.2233C22.2228 18.1881 24.001 16.3524 23.9658 14.144C23.9307 11.9355 22.095 10.1573 19.8865 10.1925Z" fill="#DFDFDF" stroke="#DFDFDF" strokeWidth="1.78051" />
        <path d="M14.3777 27.9408C14.8892 27.7068 20.3168 25.1897 24.0377 21.6556L24.5598 23.8488C24.8124 24.9456 24.4976 26.0941 23.7366 26.9053L18.8633 32.1001C18.0538 32.963 16.6237 32.7409 16.1207 31.6687L14.3777 27.9408Z" fill="#AFC0C0" />
        <path d="M21.9244 17.3063L21.4468 10.9226C21.8266 11.1671 22.7379 11.7291 23.3441 12.0202C24.1018 12.3841 23.7618 14.6453 23.7738 15.397C23.7834 15.9984 22.5449 16.9205 21.9244 17.3063Z" fill="#F4F4F4" />
        <path d="M19.8865 10.1925C17.678 10.2277 15.8999 12.0634 15.935 14.2718C15.9702 16.4803 17.8059 18.2585 20.0144 18.2233C22.2228 18.1881 24.001 16.3524 23.9658 14.144C23.9307 11.9355 22.095 10.1573 19.8865 10.1925Z" stroke="#AFC0C0" strokeWidth="1.78051" />
        <mask
            id="mask0_313_3788" maskUnits="userSpaceOnUse" x="6" y="2"
            width="26" height="26">
            <path fillRule="evenodd" clipRule="evenodd" d="M29.4526 2.2335C26.4998 1.78344 19.9753 2.04315 12.233 10.036C9.47205 12.8863 7.38069 17.2069 6.49168 19.1617L6.49156 19.162C6.04908 20.1359 6.30018 21.2598 7.03439 21.971L12.4735 27.2396C13.2291 27.9715 14.3433 28.1627 15.296 27.6939C17.2313 26.7551 21.4777 24.5121 24.2369 21.6636C31.9792 13.6707 32.0311 7.14129 31.4872 4.20431L31.4873 4.2043L31.4855 4.19535C31.3884 3.70116 31.1424 3.2485 30.7807 2.89809C30.4189 2.54768 29.9586 2.31628 29.4616 2.23492L29.4616 2.23487L29.4526 2.2335Z" fill="#87CBC8" />
        </mask>
        <g mask="url(#mask0_313_3788)">
            <ellipse
                cx="29.9545" cy="2.45804" rx="10.15" ry="6.3" transform="rotate(45.453 29.9545 2.45804)"
                fill="#AFC0C0" />
        </g>
    </svg>
);

export default IconRocket;